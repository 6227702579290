import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '/user/main',
    title: 'All Orders',
    icon: 'fas fa-address-book',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/user/add_order',
    title: 'Add Orders',
    icon: 'fas fa-shopping-basket',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/user/order/warranty-manager',
    title: 'Warranty Manager',
    icon: 'fas fa-shield-alt',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/user/reports/pronto-uploadSheet',
    title: 'Pronto Upload Sheet',
    icon: 'fas fa-upload',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/user/dispatch/address-print',
    title: 'Address Print',
    icon: 'fas fa-print',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/user/reports/all-oderDetails',
    title: 'Order Details',
    icon: 'fas fa-info-circle',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '',
  //   title: 'Facebook ChatBot',
  //   icon: 'mdi mdi-facebook',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/user/chat-bot/disableChat',
  //       title: 'Disable ChatBot',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/user/chat-bot/category-manager',
  //       title: 'Category Manager',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/user/chat-bot/manufacturer-manager',
  //       title: 'Manufacturer Manager',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/user/chat-bot/items-manager',
  //       title: 'Items Manager',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/user/chat-bot/promotion-manager',
  //       title: 'Promotion Manager',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  {
    path: '',
    title: 'Reports',
    icon: 'far fa-chart-bar',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/user/reports/order_creation',
        title: 'Order Creation',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/order_confirmation',
        title: 'Order Confirmation',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/order_pending',
        title: 'Order Pending',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/order_delivered',
        title: 'Order Delivered',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/order_returned',
        title: 'Create to Returned',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/order_canceled',
        title: 'Order Canceled',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/accuracy-rate',
        title: 'Success Rate',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/item-wise',
        title: 'Item Wise',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/dispatch-to-delivered-date-count',
        title: 'Dispatch - Delivered',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/dispatch-to-return-date-count',
        title: 'Dispatch - Returned',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/reports/dispatch-with-deliveryType',
        title: 'Dispatch With DeliveryType',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/return/based-on-returned-date',
        title: 'Return Date Based Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/user/order/dispatch-tracker',
        title: 'Dispatched Order Tracker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }
];

<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Delivery type</th>
                <th scope="col">Count</th>
                <th scope="col">Percentage</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Confirmed Orders</td>
                <td>{{confirmedOrdersCount}}</td>
                <td>{{roundTo(confirmedOrdersCount / totalOrders * 100, 2)}}%</td>
              </tr>
              <tr>
                <td>Dispatch Orders</td>
                <td>{{dispatchedOrdersCount}}</td>
                <td>{{roundTo(dispatchedOrdersCount / totalOrders * 100, 2)}}%</td>
              </tr>
              <tr>
                <td>Delivery Orders</td>
                <td>{{deliveredOrdersCount}}</td>
                <td>{{roundTo(deliveredOrdersCount / totalOrders * 100, 2)}}%</td>
              </tr>
              <tr>
                <td>Return Orders</td>
                <td>{{returnedOrdersCount}}</td>
                <td>{{roundTo(returnedOrdersCount / totalOrders * 100, 2)}}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Order Number</th>
                <th scope="col">COD</th>
                <th scope="col">Items</th>
                <th scope="col">Total Price</th>
                <th scope="col">Confirmation Status</th>
              </tr>
              </thead>
              <tbody>
              <tr (click)="routeToSingleOrderPage(indexElement.orderNumber)" *ngFor="let indexElement of rows">
                <td>{{dateFormatter(indexElement.date)}}</td>
                <td>{{timeFormatter(indexElement.date)}}</td>
                <td>{{indexElement.orderNumber}}</td>
                <td>{{indexElement.COD}}</td>
                <td data-placement="top" data-toggle="tooltip" style="cursor: pointer;"
                    title=" {{getItemName(indexElement.device1)}} | {{indexElement.numDevice1}} &#013;{{getItemName(indexElement.device2)}} | {{indexElement.numDevice2}}  &#013;{{getItemName(indexElement.device3)}} | {{indexElement.numDevice3}}  &#013;{{getItemName(indexElement.device4)}} | {{indexElement.numDevice4}}  &#013;{{getItemName(indexElement.device5)}} | {{indexElement.numDevice5}}  &#013; ">
                  <i class="fas fa-eye"></i></td>
                <td>{{indexElement.price}}</td>
                <td><span
                  class="label {{getLabelType(indexElement.deliveryStatus)}}">{{getCustomLabel(indexElement.deliveryStatus)}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">

    <div class="card">
      <div class="card-body mainBlockColor warrenty">
        <br/>
        <h3 class="orderDetails">Total Orders : {{totalOrders}} | Pending : {{pendingOrdersCount}} | Confirmed
          : {{confirmedOrdersCount}} | Dispatched : {{dispatchedOrdersCount}} | Delivered : {{deliveredOrdersCount}} |
          Canceled : {{canceledOrdersCount}} | Returned : {{returnedOrdersCount}} | Warranty
          : {{warrantyOrdersCount}}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <label class="form-control-label">Employee Name</label>
          <ng-autocomplete
            (selected)='selectedEmployee($event)'
            [data]="allUsers"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [placeHolder]="currentSelectedEmployeeName"
            [searchKeyword]="keyword">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Date Or Date-Range</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [dayTemplate]="t" [displayMonths]="1" ngModel>
            </ngb-datepicker>

            <ng-template #t let-date="date" let-focused="focused">
                <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.focused]="focused"
                      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                      class="custom-day">
                    {{ date.day }}
                </span>
            </ng-template>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Tracking Number</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Item</th>
                <th scope="col">Amount</th>
                <th scope="col">Sticker</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of rows">
                <td>{{indexElement.COD}}</td>
                <td>{{indexElement.customerName}}</td>
                <td>{{printDeviceListWithSize(indexElement.device1, indexElement.numDevice1, indexElement.device2, indexElement.numDevice2, indexElement.device3, indexElement.numDevice3, indexElement.device4, indexElement.numDevice4, indexElement.device5, indexElement.numDevice5)}}</td>
                <td>{{indexElement.totalAmount}}</td>
                <td>Sticker<br><br><br></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Delivery Date</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [(ngModel)]="selectedDate" [displayMonths]="1"
                            ngModel>
            </ngb-datepicker>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton"
                    type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";


// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker

@Component({
  selector: 'app-dispatch-to-return-date-count',
  templateUrl: './dispatch-to-return-date-count.component.html',
  styleUrls: ['./dispatch-to-return-date-count.component.css']
})
export class DispatchToReturnDateCountComponent implements OnInit {
  // This is for the range date picker
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedEmployeeName: any = "All";
  currentSelectedEmployeeEmail: any = "All";
  totalOrders: any = 0;
  confirmedOrders: any = 0;
  daysCountArray = []
  alreadyAddedDays = []
  colorScheme = {
    domain: ['#4798e8', '#22c6ab', '#ffbc34', '#ef6e6e', '#01c0c8', '#e6f2fa']
  };
  schemeType = 'ordinal';
  chartDataFeed = [];
  xAxisLabel = 'Package Count';
  yAxisLabel = 'Number Of Day Count';
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.serviceClass.getAllEmployees().subscribe(
      data => {
        let employeeData = [{email: "All", name: "All"}];

        for (let item of data) {
          employeeData.push({name: item["name"], email: item["email"]});
        }
        this.allUsers = employeeData;
      }
    );

    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

  }


  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - Order Confirmation Report.xlsx");

  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    // tslint:disable-next-line:semicolon
    before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  selectedEmployee(jsonValue: any) {
    this.currentSelectedEmployeeName = jsonValue["name"];
    this.currentSelectedEmployeeEmail = jsonValue["email"];
  }

  printValues() {
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  timeFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    let newRelativeUrl = this.router.createUrlTree(["/admin/order_details"]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl + "?orderId=" + orderNumber, '_blank');
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  updateOrderAndConfirmedCounts() {
    this.confirmedOrders = 0;
    this.totalOrders = this.rows.length;
    for (let item of this.rows) {
      if (item["deliveryStatus"] == "confirmed") {
        this.confirmedOrders += 1;
      }
    }
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "label-success confirmed";
    } else {
      return "label-danger notConfirmed";
    }
  }

  getCustomLabel(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "Confirmed";
    } else {
      return deliveryStatus;
    }
  }

  updateTable() {
    this.totalOrders += 0;
    this.confirmedOrders += 0;
    let today = new Date();
    let tempFromDate = null;
    let tempToDate = null;

    if (this.fromDate != null) {
      let fromDateObj: Date = new Date(this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day);

      fromDateObj.setDate(fromDateObj.getDate() - 1)
      tempFromDate = fromDateObj.getFullYear() + "-" + (fromDateObj.getMonth() + 1) + "-" + fromDateObj.getDate();

      if (this.toDate == null) {
        tempToDate = this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
      } else {
        tempToDate = this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
      }
    }

    let temporaryOrderNumberArray = [];
    let codArray = "";
    this.rows = [];

    this.serviceClass.getDispatchedAndDeliveredOrders(this.currentSelectedEmployeeEmail, tempFromDate, tempToDate).subscribe(
      data => {
        for (let item of data) {
          if (temporaryOrderNumberArray.includes(item["orderNumber"])) {
          } else {
            temporaryOrderNumberArray.push(item["orderNumber"]);
            let tempValue = {
              orderNumber: item["orderNumber"],
              orderDispatchedDate: item["dateAndTime"],
              orderDeliveredDate: null
            }

            if (codArray == "") {
              codArray = codArray + "'" + item["orderNumber"] + "'";
            } else {
              codArray = codArray + " , " + "'" + item["orderNumber"] + "'";
            }
            this.rows.push(tempValue);
          }
        }

        let temporaryDeliveryDateArray = [];
        if (codArray != "") {
          this.serviceClass.getReturnedDateToArray(codArray).subscribe(
            data => {
              for (let item of data) {
                if (temporaryDeliveryDateArray.includes(item["orderId"])) {
                } else {
                  temporaryDeliveryDateArray.push(item["orderId"]);

                  for (let itemsOfDeliveryDate of this.rows) {
                    if (itemsOfDeliveryDate["orderNumber"] == item["orderId"]) {
                      itemsOfDeliveryDate["orderDeliveredDate"] = item["dateAndTime"];
                      break;
                    }
                  }
                }
              }
              this.countDays();
            }
          );
        } else {
          this.daysCountArray = [{name: "Nothing dispatched on this day", value: 10}];
          this.showNotification("warning", "There is no dispatch on this day!")
          this.chartDataFeed = this.daysCountArray;
        }
      }
    );
  }

  countDays() {
    this.alreadyAddedDays = [];
    this.daysCountArray = [];

    for (let x = 1; x <= 30; x++) {
      this.alreadyAddedDays.push(x);
      this.daysCountArray.push({name: x, value: 0})
    }

    this.daysCountArray.push({name: "30+", value: 0})

    for (let item of this.rows) {
      let dateDifference = this.getDateDifference(item.orderDeliveredDate, item.orderDispatchedDate)
      if (this.alreadyAddedDays.includes(dateDifference)) {
        for (let item of this.daysCountArray) {
          if (item.name == dateDifference) {
            item.value += 1;
          }
        }
      } else {
        this.daysCountArray[30].value += 1;
      }
    }

    this.chartDataFeed = this.daysCountArray;
  }

  getDateDifference(deliveredDate: any, dispatchedDate: any) {
    let deliveredDateObj = new Date(deliveredDate);
    let dispatchedDateObj = new Date(dispatchedDate);
    // @ts-ignore
    const diffTime = Math.abs(deliveredDateObj - dispatchedDateObj);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

}

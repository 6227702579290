import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../service.service";

@Component({
  selector: 'app-cash-accepted-checking',
  templateUrl: './cash-accepted-checking.component.html',
  styleUrls: ['./cash-accepted-checking.component.css']
})
export class CashAcceptedCheckingComponent implements OnInit {

  selectedDate: any;
  dataFromDeliveredSheet: any;
  dataFromProntoJsonArray = [];
  codArray = [];
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit() {
  }


  removeUnnecessarySpaces() {
    this.dataFromDeliveredSheet = this.dataFromDeliveredSheet.split("	").join(",").split("\n").join(";");
  }

  updateTable() {
    this.dataFromProntoJsonArray = [];
    this.codArray = [];
    let nextLineSpacesRemoved = this.dataFromDeliveredSheet.split(";");

    for (let x of nextLineSpacesRemoved) {
      let indexSlittedValues = x.split(",");
      if (indexSlittedValues[0].length != 0) {
        this.dataFromProntoJsonArray.push({
          codNumber: indexSlittedValues[0],
          prontoPrice: indexSlittedValues[1],
          ourSystemPrice: ""
        })
        this.codArray.push("'" + indexSlittedValues[0] + "'");
      }
    }

    this.serviceClass.getCashAcceptedPriceList(this.codArray).subscribe(
      data => {
        for (let x = 0; x < data.length; x++) {
          for (let y = 0; y < this.dataFromProntoJsonArray.length; y++) {
            if (data[x]["COD"] == this.dataFromProntoJsonArray[y]["codNumber"]) {
              this.dataFromProntoJsonArray[y] = {
                codNumber: this.dataFromProntoJsonArray[y]["codNumber"],
                prontoPrice: this.dataFromProntoJsonArray[y]["prontoPrice"],
                ourSystemPrice: data[x]["totalAmount"]
              };
              break;
            }
          }
        }
      }
    );
  }

  getTheComparisonValues(ourValue: any, deliveryServiceValue: any, color: any) {
    if (Number(ourValue) == Number(deliveryServiceValue)) {
      if (color) {
        return "green";
      } else {
        return "Same";
      }
    } else {
      if (color) {
        return "red";
      } else {
        return "Different";
      }
    }
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
}


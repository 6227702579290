import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-address-sticker-print',
  templateUrl: './address-sticker-print.component.html',
  styleUrls: ['./address-sticker-print.component.css']
})
export class AddressStickerPrintComponent implements OnInit {

  selectedDate: any;
  currentUrl: any;
  private notifier: NotifierService;

  constructor(private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.currentUrl = window.location.origin + "/#/multiUser/addressListDetails?date=";
  }

  updateTable() {
    if (this.selectedDate != null) {
      this.openNewBackgroundTab(this.selectedDate["year"] + "-" + this.selectedDate["month"] + "-" + this.selectedDate["day"]);
    } else {
      this.showNotification("error", "Date is required **");
    }
  }

  openNewBackgroundTab(dateSelected: any) {
    var a = document.createElement("a");
    a.href = this.currentUrl + dateSelected;
    var evt = document.createEvent("MouseEvents");
    //the tenth parameter of initMouseEvent sets ctrl key
    evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0,
      true, false, false, false, 0, null);
    a.dispatchEvent(evt);
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
}

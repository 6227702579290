import { Component, OnInit } from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker

@Component({
  selector: 'app-manufacturer-manager',
  templateUrl: './manufacturer-manager.component.html',
  styleUrls: ['./manufacturer-manager.component.css']
})
export class ManufacturerManagerComponentUser implements OnInit {
  allManufacturers: any[];
  private notifier: NotifierService;
  id: any;
  image_url: any;
  subtitle: any;
  subtitle_sinhala: any;
  title: any;
  title_sinhala: any;
  priority: any;
  createStatus = true;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.serviceClass.getManufacturerAllType().subscribe(
      data => {
        this.allManufacturers = data;
      }
    );
  }

  addManufacturer = () => {
    this.serviceClass.addManufacturer(this.image_url, this.subtitle, this.subtitle_sinhala, this.title, this.title_sinhala,this.priority).subscribe(
      data => {
        this.serviceClass.getManufacturerAllType().subscribe(
          data => {
            this.allManufacturers = data;
            this.showNotification("success","Manufacturer added successfully!");
          }
        );
      }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  editManufacturer(id: any, title: any, title_sinhala: any, subtitle: any, subtitle_sinhala: any, image_url: any, priority: any) {
    window.scroll(0, 0);
    this.createStatus = false;
    this.id = id;
    this.title = title;
    this.title_sinhala = title_sinhala;
    this.subtitle = subtitle;
    this.subtitle_sinhala = subtitle_sinhala;
    this.image_url = image_url;
    this.priority = priority;
  }

  cancelCategories() {
    this.createStatus = true;
    this.id = 0;
    this.title = "";
    this.title_sinhala = "";
    this.subtitle = "";
    this.subtitle_sinhala = "";
    this.image_url = "";
    this.priority = 0;
  }

  updateEditManufacturers(){
    this.serviceClass.updateManufacturer(this.id,this.image_url, this.subtitle, this.subtitle_sinhala, this.title, this.title_sinhala, this.priority).subscribe(
      data => {
        if(data) {
          this.serviceClass.getManufacturerAllType().subscribe(
            data => {
              this.allManufacturers = data;
              this.showNotification("success", "Manufacturer updated successfully!");
            }
          );
        }
      }
    );
  }

  deleteCategory(id){
    alert("Not Authorized to deleted the selected row!")
    // if(confirm("Do you want to delete this?")) {
    //   this.serviceClass.deleteManufacturer(id).subscribe(
    //     data => {
    //       if(data) {
    //         this.serviceClass.getManufacturerAllType().subscribe(
    //           data => {
    //             this.allManufacturers = data;
    //             this.showNotification("success", "Manufacturer deleted successfully!");
    //           }
    //         );
    //       }
    //     }
    //   );
    // }
  }
}

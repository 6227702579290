import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ServiceService} from "../../../service.service";
import {NgForm} from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-navigation-user',
  templateUrl: './navigation.component.html'
})
export class NavigationComponentUser implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  canceledCount: any = 0;
  confirmedCount: any = 0;
  deliveredCount: any = 0;
  dispatchedCount: any = 0;
  pendingCount: any = 0;
  returnCount: any = 0;
  warrentyCount: any = 0;
  userName: any;
  emailAddress: any;
  searchKeyword: any;
  currentTime: any;
  userImageProfileImage: Boolean;


  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  constructor(private modalService: NgbModal, private router: Router, private serviceClass: ServiceService) {
    setInterval(() => {
      let todayDate = new Date();
      const helper = new JwtHelperService();
      const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
      this.userName = dataValues.empName;
      this.emailAddress = dataValues.empEmail;
      this.userImageProfileImage = dataValues.imageThumbnail;
      this.currentTime = todayDate.getHours() + ":" + this.getTwoDigits(todayDate.getMinutes()) + ":" + this.getTwoDigits(todayDate.getSeconds()) + " " + todayDate.getDate() + "/" + (todayDate.getMonth() + 1) + "/" + todayDate.getFullYear();
    }, 1000);
  }

  getTwoDigits(value: any) {
    let valueNew = value.toString();

    if (valueNew.length == 1) {
      return "0" + value;
    } else {
      return value;
    }
  }

  ngOnInit(): void {

  }

  logout() {
    localStorage.setItem('user_details', "")
    this.router.navigate(['/']);
  }

  updateDayOrderCount() {
    this.serviceClass.getAllItemCounts().subscribe(
      data => {
        // @ts-ignore
        this.canceledCount = data.canceledCount;
        // @ts-ignore
        this.confirmedCount = data.confirmedCount;
        // @ts-ignore
        this.deliveredCount = data.deliveredCount;
        // @ts-ignore
        this.dispatchedCount = data.dispatchedCount;
        // @ts-ignore
        this.pendingCount = data.pendingCount;
        // @ts-ignore
        this.returnCount = data.returnCount;
        // @ts-ignore
        this.warrentyCount = data.warrentyCount;
      }
    );
  }

  routeToMainPage(searchingValues: NgForm) {
    const helper = new JwtHelperService();
    let empType = "";
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];

    if (dataValues["empPost"] != "admin") {
      empType = "admin";
    } else {
      empType = "user";
    }
    this.showSearch = false;
    this.router.navigate(['/' + empType + '/main'], {queryParams: {keyword: searchingValues.value["searchKeyword"]}});
  }

  updatePrivileges() {
    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
    this.serviceClass.updatePrivileges(dataValues.empEmail).subscribe(
      data => {
        // @ts-ignore
        if (data !== "false") {
          // @ts-ignore
          localStorage.setItem('user_details', data);
        } else {
          this.router.navigate(['/']);
        }
      }
    );
  }

  getImageUrl(){
    if(this.userImageProfileImage == null){
      return "../../../../assets/images/users/userProfileIcon.png";
    }else{
      return this.userImageProfileImage;
    }
  }

  routeToSingleOrderPage() {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/profile']);
  }
}

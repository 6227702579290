import {Component, OnInit, ViewChild} from '@angular/core';
import {ServiceService} from '../../../service.service';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {NgxSpinnerService} from "ngx-spinner";
import {timer} from "rxjs";
import * as JSZip from 'jszip';

const data: any = [];
let displayingNumberOfRows: any = 10;

@Component({
  selector: 'app-data-table',
  templateUrl: './main-orders.component.html',
  styleUrls: ['./main-orders.css']
})

export class MainOrdersComponent implements OnInit {
  rows = [];
  temp = [...data];
  phoneDetailsJsonArray = [];
  // @ts-ignore
  @ViewChild(MainOrdersComponent) table: MainOrdersComponent;
  deliveryStatusBtn: any = 'Delivery Status';
  numberOfRowsBtn: any = 'Number Of Rows';
  userArray: any = [];
  allDeliveryType: any = [];
  keywordToSearch: any;
  private notifier: NotifierService;
  private data: any[];
  private searchedValue: string = '';

  constructor(private spinner: NgxSpinnerService, private serviceClass: ServiceService, notifier: NotifierService, private route: ActivatedRoute, private router: Router, private activatedRoute: ActivatedRoute) {
    this.notifier = notifier;
    this.rows = data;
    this.temp = [...data];

    this.activatedRoute.queryParams.subscribe(params => {
      this.keywordToSearch = params['keyword'];
    });

    const timerIncrement = timer(30000, 30000);
    timerIncrement.subscribe(x => {
      this.serviceClass.refreshMainTableData(localStorage.getItem("last-main-pageUpdated-Time")).subscribe(
        data => {
          if (Number(data[0]["orderCount"]) > 0) {
            this.defaultTableUpdate();
          }
        }
      );
    });
  }

  showSpin = () => {
    this.spinner.show(undefined,
      {
        type: 'ball-scale-multiple',
        size: 'medium',
        bdColor: 'rgba(51,61,84, .7)',
        color: 'white',
        fullScreen: false
      }
    );
  }

  hideSpin = () => {
    this.spinner.hide()
  }

  ngOnInit() {

    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

    this.serviceClass.getAllUsers().subscribe(
      data => {
        this.userArray = data;
      }
    );

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.allDeliveryType = data;
      }
    )

    if (this.keywordToSearch != undefined) {
      this.serviceClass.getFilteredData(this.keywordToSearch).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
        }
      );
    } else {
      this.defaultTableUpdate();
    }
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - Main Page.xlsx");
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getUserNameFromEmail = (email: any) => {
    for (let users of this.userArray) {
      if (users["empEmail"] == email) {
        return users["empName"];
      }
    }
  }

  defaultTableUpdate = () => {
    this.showSpin();
    this.serviceClass.getAllOrders(displayingNumberOfRows).subscribe(
      data => {
        this.data = data;
        this.rows = [...this.data];
        this.hideSpin();
      }
    );
    const dateObj = new Date();
    const currentTime = dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1) + "-" + dateObj.getDate() + " " + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds();
    localStorage.setItem("last-main-pageUpdated-Time", currentTime);
  }

  filterBySearch(event) {
    this.showSpin();
    const val = event.target.value.toLowerCase();
    this.searchedValue = val;
    if (val == '' && this.deliveryStatusBtn != 'Default' && this.deliveryStatusBtn != 'Delivery Status') {
      this.serviceClass.getFilteredData(this.deliveryStatusBtn).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    } else if (val == '') {
      this.defaultTableUpdate();
    } else if (this.deliveryStatusBtn != 'Default' && this.deliveryStatusBtn != 'Delivery Status') {
      this.serviceClass.getFilteredData2(val, this.deliveryStatusBtn).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    } else {
      this.serviceClass.getFilteredData(val).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    }
  }

  filterWithDeliveryStatus(deliveryType: any) {
    this.showSpin();
    this.deliveryStatusBtn = deliveryType;

    if (deliveryType == 'Default' && this.searchedValue != '') {
      this.serviceClass.getFilteredData(this.searchedValue).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    } else if (deliveryType == 'Default') {
      this.defaultTableUpdate();
    } else if (this.searchedValue != '') {
      this.serviceClass.getFilteredData2(this.searchedValue, this.deliveryStatusBtn).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    } else {
      this.serviceClass.getFilteredData(deliveryType).subscribe(
        data => {
          this.data = data;
          this.rows = [...this.data];
          this.hideSpin();
        }
      );
    }
  }


  updateDisplayingNumberOfRows(count: any) {
    this.numberOfRowsBtn = count;
    displayingNumberOfRows = count;
    this.defaultTableUpdate();
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "pending") {
      return "label-primary pending";
    } else if (deliveryStatus == "confirmed") {
      return "label-info confirmed";
    } else if (deliveryStatus == "dispatched") {
      return "label-warning dispatched";
    } else if (deliveryStatus == "delivered") {
      return "label-success delivered";
    } else if (deliveryStatus == "return") {
      return "label-danger return";
    } else if (deliveryStatus == "canceled") {
      return "label-warning canceled";
    } else if (deliveryStatus == "warrenty") {
      return "label-secondary warrenty";
    }
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  routeToSingleOrderPage(orderNumber: any) {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getDeliveryTypeName(deliveryType: any) {
    for (let item of this.allDeliveryType) {
      if (item["id"] == deliveryType) {
        return item["name"];
      }
    }
  }

  getToolTipForOrderDetails(itemName: any, numDevice1: any, itemName2: any, numDevice2: any, itemName3: any, numDevice3: any, itemName1: any | string, numDevice4: any, itemName4: any | string, numDevice5: any) {
    let itemDetails = "";
    itemDetails += itemName  != "No Item" ? itemName+" : "+numDevice1:"";
    itemDetails += itemName1 != "No Item" ? "\n" +itemName1+" : "+numDevice2:"";
    itemDetails += itemName2 != "No Item" ? "\n" +itemName2+" : "+numDevice3:"";
    itemDetails += itemName3 != "No Item" ? "\n" +itemName3+" : "+numDevice4:"";
    itemDetails += itemName4 != "No Item" ? "\n" +itemName4+" : "+numDevice5:"";
    return itemDetails;
  }

  getNoteToolTip(note1: any, note2: any, note3: any) {
    let noteContent = "";
    if(note1 != null && note1 != "null" && note1.length > 0){
      noteContent += "Note 1 : " + note1;
    }
    if(note2 != null && note1 != "null" && note1.length > 0){
      noteContent += "\nNote 2 : " + note2;
    }
    if(note3 != null && note1 != "null" && note1.length > 0){
      noteContent += "\nNote 3 : " + note3;
    }
    if(noteContent == ""){
      return "No Comment";
    }else{
      return noteContent;
    }
  }
}
//Himal Kavishan

import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router} from '@angular/router';
import {Observable, timer} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceService} from './service.service';
import {NotifierService} from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {



  constructor(private spinner: NgxSpinnerService, private serviceClass: ServiceService, notifier: NotifierService, private route: ActivatedRoute, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  canActivate():boolean {
    if(localStorage.getItem('user_details') == null){
      this.router.navigate(['/login']);
      return false;
    }else{
      const jwtLib = new JwtHelperService();
      if(jwtLib.isTokenExpired(localStorage.getItem('user_details'))){
        this.router.navigate(['/login']);
        console.log('Token is expired! Please check your device date and time.');
        return false;
      }else{
        return true;
      }
    }
  }
}

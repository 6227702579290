<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label">Title</label>
            <input [(ngModel)]="title" class="form-control" ngModel type="text" value="{{title}}"/>
          </div>

          <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label">Title (Sinhala)</label>
            <input [(ngModel)]="title_sinhala" class="form-control" ngModel type="text" value="{{title_sinhala}}"/>
          </div>

          <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label">Subtitle</label>
            <input [(ngModel)]="subtitle" class="form-control" ngModel type="text" value="{{subtitle}}"/>
          </div>

          <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label">Subtitle (Sinhala)</label>
            <input [(ngModel)]="subtitle_sinhala" class="form-control" ngModel type="text" value="{{subtitle_sinhala}}"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label">Image URL</label>
            <input [(ngModel)]="image_url" class="form-control" ngModel type="text" value="{{image_url}}"/>
          </div>
          <div class="col-lg-3 col-md-5 m-t-10 m-b-10" *ngIf="createStatus">
            <label class="form-control-label"> &nbsp;</label>
            <button class="btn btn-rounded btn-block btn-outline-success halfButton" type="button" (click)="addCategories()">
              Create
            </button>
          </div>
          <div class="col-lg-3 col-md-5 m-t-10 m-b-10" *ngIf="!createStatus">
            <label class="form-control-label"> &nbsp;</label>
            <button class="btn btn-rounded btn-block btn-outline-success halfButton" type="button" (click)="updateEditCategories()">
              Update
            </button>
          </div>
          <div class="col-lg-2 col-md-5 m-t-10 m-b-10">
            <label class="form-control-label"> &nbsp;</label>
            <button class="btn btn-rounded btn-block btn-outline-danger halfButton" type="button" (click)="cancelCategories()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Title</th>
                <th scope="col">In Sinhala</th>
                <th scope="col">SubTitle</th>
                <th scope="col">In Sinhala</th>
                <th scope="col">Image</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of allCategories">
                <td>{{indexElement.id}}</td>
                <td>{{indexElement.title}}</td>
                <td>{{indexElement.title_sinhala}}</td>
                <td>{{indexElement.subtitle}}</td>
                <td>{{indexElement.subtitle_sinhala}}</td>
                <td><img class="categoryImage" src="{{indexElement.image_url}}"></td>
                <td class="pointer" (click)="editCategories(indexElement.id,indexElement.title,indexElement.title_sinhala,indexElement.subtitle,indexElement.subtitle_sinhala,indexElement.image_url)"><i class="fa fa-edit"></i></td>
                <td class="pointer" (click)="deleteCategory(indexElement.id)"><i class="fa fa-trash red"></i></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>

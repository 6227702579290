<div class="row">
  <div class="col-lg-3"></div>
  <div class="col-lg-6" style="margin-top: 50px">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-9">
        <input [(ngModel)]="idNumber" class="form-control" ngModel type="text" value="{{idNumber}}" placeholder="Please enter users ref number,"/>
          </div>

          <div class="col-lg-3">
            <button class="btn btn-rounded btn-block btn-outline-success halfButton" type="button" (click)="disableOnClick()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3"> </div>
</div>

<notifier-container></notifier-container>

import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ServiceService} from '../../../service.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-disable-chat',
  templateUrl: './disable-chat.component.html',
  styleUrls: ['./disable-chat.component.css']
})
export class DisableChatComponent implements OnInit {
  idNumber: any;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {

  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  disableOnClick() {
    this.serviceClass.disableChatBot(this.idNumber).subscribe(
      data => {
        if(data.length === 0 ){
          this.showNotification("error","Please enter valid Id**");
        } else {
          if (data[0].chatBotOn === "false") {
            this.showNotification("success", "Chat has been disabled successfully");
          } else {
            this.showNotification("error", "Chat is not disabled**");
          }
        }
      }
    );
  }
}

import {Component, OnInit} from '@angular/core';
import {ROUTES} from './menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JwtHelperService} from "@auth0/angular-jwt";
import {ServiceService} from '../../../service.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // End open close
  userName: any;
  empEmail: any;
  userImageProfileImage: any;

  constructor(
    private serviceClass: ServiceService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    setInterval(() => {
      let todayDate = new Date();
      const helper = new JwtHelperService();
      const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
      this.userName = dataValues.empName;
      this.userImageProfileImage = dataValues.imageThumbnail;
      }, 1000);
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
    this.userName = dataValues.empName;
    this.empEmail = dataValues.empEmail;
    this.userImageProfileImage = dataValues.imageThumbnail;
  }

  logout() {
    localStorage.setItem('user_details', "")
    this.router.navigate(['/']);
  }

  getImageUrl(){
    if(this.userImageProfileImage == null){
      return "../../../../assets/images/users/userProfileIcon.png";
    }else{
      return this.userImageProfileImage;
    }
  }

  routeToSingleOrderPage() {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/profile']);
  }

}

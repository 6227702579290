<div class="book">
  <div class="page">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <h3><b>Enter COD And Prices,</b></h3>
        <textarea (change)="removeUnnecessarySpaces()" [(ngModel)]="dataFromDeliveredSheet" class="textArea"></textarea>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <br/>
    <br/>

    <div class="row">
      <div class="col-lg-12">
        <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton" type="button">
          Check Pricing
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
          <thead>
          <tr>
            <th scope="col">COD</th>
            <th scope="col">System Price</th>
            <th scope="col">Pronto Price</th>
            <th scope="col">Comparison</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let indexElement of dataFromProntoJsonArray">
            <td>{{indexElement.codNumber}}</td>
            <td>{{indexElement.ourSystemPrice}}</td>
            <td>{{indexElement.prontoPrice}}</td>
            <td
              class="{{getTheComparisonValues(indexElement.ourSystemPrice , indexElement.prontoPrice,true)}}">{{getTheComparisonValues(indexElement.ourSystemPrice, indexElement.prontoPrice, false)}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

import {Routes} from '@angular/router';

import {FullComponent} from './Admin/layouts/full/full.component';
import {FullComponentUSER} from './User/layouts/full/full.component';
import {AddressStickerWithDateComponent} from "./Common/address-sticker-with-date/address-sticker-with-date.component";
import {InvoiceComponent} from "./Admin/invoice/invoice.component";
import {BlankComponentAll} from "./blankLayout/blank.component";
import {AddressPrintA6Component} from "./Admin/address-print-a6/address-print-a6.component";
import {AuthGuard} from './auth.guard';

export const Approutes: Routes = [
  {
    path: 'admin',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./Admin/dashboard.module').then(m => m.DashboardModule)
      }
    ],
    canActivate:[AuthGuard]
  },
  {
    path: 'user',
    component: FullComponentUSER,
    children: [
      {
        path: '',
        loadChildren: () => import('./User/dashboard-module-user.module').then(m => m.DashboardModuleUser)
      }
    ],
    canActivate:[AuthGuard]
  },
  {
    path: '',
    component: BlankComponentAll,
    children: [
      {
        path: '',
        loadChildren: () => import('./Authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },

  {
    path: 'multiUser/addressListDetails',
    component: AddressStickerWithDateComponent,
    canActivate:[AuthGuard]
  },

  {
    path: 'invoice/show',
    component: InvoiceComponent,
    canActivate:[AuthGuard]
  },

  {
    path: 'address/show',
    component: AddressPrintA6Component,
    canActivate:[AuthGuard]
  }
];

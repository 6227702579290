<div class="book">
  <div class="page">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <br><br><br><br><br><br><br><br><br>
        <button (click)="downloadBackup()" class="btn btn-rounded btn-block btn-outline-primary halfButton" type="button">
          Download BackUp
        </button>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <br/>
    <br/>
  </div>
</div>
<notifier-container></notifier-container>

import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-bulk-order-delivered',
  templateUrl: './bulk-order-delivered.component.html',
  styleUrls: ['./bulk-order-delivered.component.css']
})
export class BulkOrderDeliveredComponent implements OnInit {

  agentEmail: any = 'sasa.uoc@gmail.com';
  selectedDate: any;
  dataFromDeliveredSheet: any;
  dataFromProntoJsonArray = [];
  codArray = [];
  setButtonStatus: any = true;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    const helper = new JwtHelperService();
    this.agentEmail = helper.decodeToken(localStorage.getItem('user_details')).rows[0]["empEmail"];
  }

  ngOnInit() {
  }


  removeUnnecessarySpaces() {
    this.dataFromDeliveredSheet = this.dataFromDeliveredSheet.split("	").join(",").split("\n").join(";");
  }

  updateTable() {
    this.setButtonStatus = false;
    this.dataFromProntoJsonArray = [];
    this.codArray = [];
    let nextLineSpacesRemoved = this.dataFromDeliveredSheet.split(";");

    for (let x of nextLineSpacesRemoved) {
      let indexSlittedValues = x.split(",");
      if (indexSlittedValues[0].length != 0) {
        this.dataFromProntoJsonArray.push({
          codNumber: indexSlittedValues[0],
          prontoPrice: indexSlittedValues[1],
          ourSystemPrice: "",
          deliveryStatus:"",
          orderId:"",
          orderStatus:"",
          updateStatus:""
        })
        this.codArray.push("'" + indexSlittedValues[0] + "'");
      }
    }

    this.serviceClass.getCashAcceptedPriceList(this.codArray).subscribe(
      data => {
        for (let x = 0; x < data.length; x++) {
          for (let y = 0; y < this.dataFromProntoJsonArray.length; y++) {
            if (data[x]["COD"] == this.dataFromProntoJsonArray[y]["codNumber"]) {
              this.dataFromProntoJsonArray[y] = {
                codNumber: this.dataFromProntoJsonArray[y]["codNumber"],
                prontoPrice: this.dataFromProntoJsonArray[y]["prontoPrice"],
                ourSystemPrice: data[x]["totalAmount"],
                deliveryStatus:data[x]["deliveryStatus"],
                orderId:data[x]["orderNumber"],
                updateStatus:"NOT Updated"
              };
              break;
            }
          }
        }
        this.updateDelivery(this.dataFromProntoJsonArray);
      }
    );
  }

  updateDelivery(array:any){
    for(let element of array){
      if(element.deliveryStatus == "dispatched" && (element.prontoPrice == element.ourSystemPrice)){
        if(this.updateDeliveryType(element.orderId)){
          element.deliveryStatus = "delivered";
          element.updateStatus = "Updated";
        }else{
          element.updateStatus = "NOT Updated";
        }
      }
    }
    this.showNotification("success","Update successful.")
    this.setButtonStatus = true;
    this.updateDataTableAfterEdit();
  }

  // @ts-ignore
  updateDeliveryType(orderId:any):Observable<Boolean>{
    this.serviceClass.updateDeliveryStatus(orderId,"delivered",this.agentEmail).subscribe(
      data => {
        return true;
      },error => {
        console.log(error);
        return false;
      }
    );
  }

  updateDataTableAfterEdit(){
    this.serviceClass.getCashAcceptedPriceList(this.codArray).subscribe(
      data => {
        for (let x = 0; x < data.length; x++) {
          for (let y = 0; y < this.dataFromProntoJsonArray.length; y++) {
            if (data[x]["COD"] == this.dataFromProntoJsonArray[y]["codNumber"]) {
              this.dataFromProntoJsonArray[y] = {
                codNumber: this.dataFromProntoJsonArray[y]["codNumber"],
                prontoPrice: this.dataFromProntoJsonArray[y]["prontoPrice"],
                ourSystemPrice: this.dataFromProntoJsonArray[y]["ourSystemPrice"],
                deliveryStatus:data[x]["deliveryStatus"],
                orderId:this.dataFromProntoJsonArray[y]["orderId"],
                updateStatus:this.dataFromProntoJsonArray[y]["updateStatus"]
              };
              break;
            }
          }
        }
        console.log(this.dataFromProntoJsonArray);
      }
    );
  }

  getDeliveryStatusColors(status:any){
    if(status == "delivered"){
      return "green";
    }else{
      return "red"
    }
  }

  getTheComparisonValues(ourValue: any, deliveryServiceValue: any, color: any) {
    if (Number(ourValue) == Number(deliveryServiceValue)) {
      if (color) {
        return "green";
      } else {
        return "Same";
      }
    } else {
      if (color) {
        return "red";
      } else {
        return "Different";
      }
    }
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
}

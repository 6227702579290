import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";
import {JwtHelperService} from '@auth0/angular-jwt';
import {getElementHeight} from '@nebular/theme/components/helpers';


// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker

@Component({
  selector: 'app-dispatch-tracker',
  templateUrl: './dispatch-tracker.component.html',
  styleUrls: ['./dispatch-tracker.component.css']
})
export class DispatchTrackerComponent implements OnInit {
  // This is for the range date picker
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedDeliveryTypeID: any = "All";
  selectedDeliveryTypeName: any = "All";
  totalOrders: any = 0;
  pendingOrdersCount: any = 0;
  dispatchedOrdersCount: any = 0;
  confirmedOrdersCount: any = 0;
  deliveredOrdersCount: any = 0;
  returnedOrdersCount: any = 0;
  warrantyOrdersCount: any = 0;
  canceledOrdersCount: any;
  private notifier: NotifierService;
  clientStatus: any;
  clientComment: any;
  currentlyUpdatingOrderNumber = 0;
  selectedDate: any;
  clientList :any = [];
  prontoTrackingDetails :any = [];

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.allUsers = data;
      }
    );

    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - Dispatch Based On Delivery Type.xlsx");
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.updateTable(this.selectedDate)
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    // tslint:disable-next-line:semicolon
    before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  selectedDeliveryType(jsonValue: any) {
    this.currentSelectedDeliveryTypeID = jsonValue["id"];
    this.selectedDeliveryTypeName = jsonValue["name"];
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  timeFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  printInAnotherPage() {
    const styling = `<style>
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #eaeaea;
}
</style>`
    let printElement = document.getElementById("tableOfData").innerHTML;
    const newWin = open('url','windowName','height=900,width=1200');
    newWin.document.write(styling);
    newWin.document.write('Total Count : '+ this.totalOrders);
    newWin.document.write(printElement);
  }

  updateOrderAndConfirmedCounts() {
    this.pendingOrdersCount = 0;
    this.dispatchedOrdersCount = 0;
    this.confirmedOrdersCount = 0;
    this.deliveredOrdersCount = 0;
    this.canceledOrdersCount = 0;
    this.returnedOrdersCount = 0;
    this.warrantyOrdersCount = 0;

    this.totalOrders = this.rows.length;
    for (let item of this.rows) {
      if (item["clientStatus"].toLowerCase() == 'pending') {
        this.pendingOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'confirmedWithCOD' || item["deliveryStatus"].toLowerCase() == 'confirmed') {
        this.confirmedOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'dispatched') {
        this.dispatchedOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'delivered') {
        this.deliveredOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'canceled') {
        this.canceledOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'return') {
        this.returnedOrdersCount += 1;
      } else if (item["clientStatus"].toLowerCase() == 'warrenty') {
        this.warrantyOrdersCount += 1;
      }
    }
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "pending") {
      return "label-primary pending";
    } else if (deliveryStatus == "confirmed") {
      return "label-info confirmed";
    } else if (deliveryStatus == "dispatched") {
      return "label-warning dispatched";
    } else if (deliveryStatus == "delivered") {
      return "label-success delivered";
    } else if (deliveryStatus == "return") {
      return "label-danger return";
    } else if (deliveryStatus == "canceled") {
      return "label-warning canceled";
    } else if (deliveryStatus == "warrenty") {
      return "label-secondary warrenty";
    }
  }

  updateTable(date) {
    this.clientList = [];
    this.totalOrders += 0;
    this.confirmedOrdersCount += 0;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    let tempFromDate = null;
    let tempToDate = null;

    if (this.fromDate != null) {
      let fromDateObj: Date = new Date(date);

      // fromDateObj.setDate(fromDateObj.getDate() - 1);
      tempFromDate = fromDateObj.getFullYear() + "-" + (fromDateObj.getMonth() + 1) + "-" + fromDateObj.getDate();

      if (this.toDate == null) {
        tempToDate = this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
      } else {
        tempToDate = this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
      }
    }

    let temporaryOrderNumberArray = [];
    this.rows = [];

    this.serviceClass.getDispatchBasedOnDeliveryType(this.currentSelectedDeliveryTypeID, date, tempToDate).subscribe(
      data => {
        for (let item of data) {
          if (temporaryOrderNumberArray.includes(item["orderNumber"])) {
          } else {
            temporaryOrderNumberArray.push(item["orderNumber"]);
            let tempValue = {
              orderNumber: item["orderNumber"],
              COD: item["COD"],
              date: item["dateAndTime"],
              customerName: item["customerName"],
              phoneNumber: item["phoneNumber"],
              city: item["city"],
              fixedLine: item["fixedLine"],
              clientStatus: item["clientStatus"],
              dStatusNote1: this.updateUsersList(item,"dStatusNote1"),
              dStatusNote2: this.updateUsersList(item,"dStatusNote2"),
              dStatusNote3: this.updateUsersList(item,"dStatusNote3"),
              dStatusNote4: this.updateUsersList(item,"dStatusNote4"),
              dStatusNote5: this.updateUsersList(item,"dStatusNote5"),
              dStatusNote6: this.updateUsersList(item,"dStatusNote6"),
              dStatusNote7: this.updateUsersList(item,"dStatusNote7"),
              dStatusNote8: this.updateUsersList(item,"dStatusNote8"),
              dStatusNote9: this.updateUsersList(item,"dStatusNote9"),
              dStatusNote10: this.updateUsersList(item,"dStatusNote10"),
              deliveryStatus: item["deliveryStatus"]
            }
            this.rows.push(tempValue);
          }
        }

        let tempDispatchedOrders = [];
        let tempDeliveredOrders = [];
        let tempReturnOrders = [];

        this.rows.forEach(value=>{
          if(value.clientStatus === "dispatched"){
            tempDispatchedOrders.push(value);
          } else if(value.clientStatus === "delivered"){
            tempDeliveredOrders.push(value);
          } else {
            tempReturnOrders.push(value);
          }
        })
        tempDispatchedOrders = tempDispatchedOrders.concat(tempReturnOrders)
        this.rows = tempDispatchedOrders.concat(tempDeliveredOrders)
        this.updateOrderAndConfirmedCounts();
      }
    );
  }

  getCodDetails(cod){
    this.serviceClass.getOrderStatus(cod).subscribe(
      data => {
        this.prontoTrackingDetails = data.tracking_no;
        this.prontoTrackingDetails.sort(function(a,b){
          // @ts-ignore
          return new Date(a.STATUS_DATE) - new Date(b.STATUS_DATE);
          }
        );

        this.prontoTrackingDetails.forEach(value=>{
          if(value.STATUS === "A"){
            value.STATUS = "Accepted by Pronto Branch";
          } else if(value.STATUS === "I"){
            value.STATUS = "Data Entry to System";
          } else if(value.STATUS === "S"){
            value.STATUS = "Scanned Out to Delivery Route";
          } else if(value.STATUS === "C"){
            value.STATUS = "Consignment Delivery Complete";
          }
        })
        document.getElementById("trackingDetailsOpen").click();
      }
    );
  }

  updateUsersList(value,id){
    if(value[id] != null && value[id] != "" ) {
      let parts = value[id].split("!@#");
      if(!this.clientList.includes(parts[1])){
        this.clientList.push(parts[1]);
      }
      return parts[0]
    }
    return "-";
  }

  getConfirmedDate(dataArray: any[], orderId: any) {
    for (let item of dataArray) {
      if (item["orderNumber"] == orderId) {
        if (item["properName"] == "confirmed" || item["properName"] == "confirmedWithCOD") {
          return item["dateAndTime"];
        }
      }
    }
  }

  getDispatchedDate(dataArray: any[], orderId: any) {
    for (let item of dataArray) {
      if (item["orderNumber"] == orderId) {
        if (item["properName"] == "dispatched") {
          return item["dateAndTime"];
        }
      }
    }
  }

  getReturnedDate(dataArray: any[], orderId: any) {
    for (let item of dataArray) {
      if (item["orderNumber"] == orderId) {
        if (item["properName"] == "return") {
          return item["dateAndTime"];
        }
      }
    }
  }

  selectedDateUpdate(value:any) {
    this.updateTable(this.selectedDate);
  }

  getDate(number){
    try {
      let fromDateObj: Date = new Date(this.selectedDate);
      // @ts-ignore
      if(!isNaN(fromDateObj)) {
        fromDateObj.setDate(fromDateObj.getDate() + number);
        return fromDateObj.getDate() + "/" + (fromDateObj.getMonth() + 1)
      } else {
        return "Day "+ number;
      }
    }catch (e){
      return "Day "+ number;
    }
  }

  updateOrderUserDetails(orderNumber: any , clientStatus: any, description:any) {
    this.clientComment = description;
    this.clientStatus = clientStatus;
    this.currentlyUpdatingOrderNumber = orderNumber;
    document.getElementById("updateValueToggles").click();
  }

  getDifferentTime (){
    let selectedDate: Date = new Date(this.selectedDate);
    const tempDate = new Date();
    let todayDate: Date = new Date(tempDate.getFullYear() + "-" + (tempDate.getMonth()+1) + "-" + tempDate.getDate());
    // @ts-ignore
    const diffTime = Math.abs(selectedDate - todayDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  updateDetails() {

    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
    let selectedDate: Date = new Date(this.selectedDate);
    const tempDate = new Date();
    let todayDate: Date = new Date(tempDate.getFullYear() + "-" + (tempDate.getMonth()+1) + "-" + tempDate.getDate());
    // @ts-ignore
    const diffTime = Math.abs(selectedDate - todayDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.serviceClass.updateDispatchTrackerDetails(this.clientStatus,this.clientComment+"!@#"+dataValues.empName,this.currentlyUpdatingOrderNumber,"dStatusNote"+diffDays).subscribe(
      data => {
        document.getElementById("closeButton").click();
        this.clientStatus = 'pending';
        this.clientComment = '';
        if(data === true){
          this.showNotification("success","Successfully updated.");
          this.updateTable(this.selectedDate);
        }else {
          this.showNotification("error","Error occurred!");
          this.updateTable(this.selectedDate);
        }
      });
  }

  getRoundNumber(number: number) {
    if(isNaN(Math.round(number*100)/100)){
      return 0;
    } else {
      return Math.round(number*100)/100;
    }
  }

  getColor(clientStatus: any) {
    if(clientStatus === "dispatched"){
      return '#ffffff';
    } else if(clientStatus === "delivered"){
      return '#f2ffe5';
    } else if(clientStatus === "return"){
      return '#FFE5E5';
    }
  }
}

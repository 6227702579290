<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">COD</th>
                <th scope="col">Pending Date</th>
                <th scope="col">Pending Time</th>
                <th scope="col">Agent</th>
                <th scope="col">Current Status</th>
              </tr>
              </thead>
              <tbody>
              <tr (click)="routeToSingleOrderPage(indexElement.orderNumber)" *ngFor="let indexElement of rows">
                <td>{{dateFormatter(indexElement.date)}}</td>
                <td>{{indexElement.COD}}</td>
                <td>{{dateFormatter(indexElement.dateAndTime)}}</td>
                <td>{{timeFormatter(indexElement.dateAndTime)}}</td>
                <td>{{indexElement.agent}}</td>
                <td><span
                  class="label {{getLabelType(indexElement.deliveryStatus)}}">{{indexElement.deliveryStatus}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">

    <div class="card">
      <div class="card-body mainBlockColor warrenty">
        <br/>
        <h3 class="orderDetails">Total Orders : {{totalOrders}} | Pending : {{pendingOrdersCount}} | Confirmed
          : {{confirmedOrdersCount}} | Dispatched : {{dispatchedOrdersCount}} | Delivered : {{deliveredOrdersCount}} |
          Canceled : {{canceledOrdersCount}} | Returned : {{returnedOrdersCount}} | Warranty
          : {{warrantyOrdersCount}}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <label class="form-control-label">Employee Name</label>
          <ng-autocomplete
            (selected)='selectedEmployee($event)'
            [data]="allUsers"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [placeHolder]="currentSelectedEmployeeName"
            [searchKeyword]="keyword">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

        <br>
        <br>

        <div class="row">
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>


          <p>Selected range: {{range.value | json}}</p>

          <div class="col-lg-12">
            <label class="form-control-label">Date Or Date-Range</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">

            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [dayTemplate]="t" [displayMonths]="1" ngModel>
            </ngb-datepicker>

            <ng-template #t let-date="date" let-focused="focused">
                <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.focused]="focused"
                      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                      class="custom-day">
                    {{ date.day }}
                </span>
            </ng-template>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton"
                    type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

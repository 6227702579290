<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">COD</th>
                <th scope="col">Dispatch Date</th>
                <th scope="col">Returned Date</th>
                <th scope="col">Confirm Agent</th>
                <th scope="col">Reason to Return</th>
                <th scope="col">Check By</th>
                <th scope="col">Mistake By</th>
                <th scope="col">Authorized By</th>
              </tr>
              </thead>
              <tbody>
              <tr (click)="routeToSingleOrderPage(indexElement.orderNumber)" *ngFor="let indexElement of rows">
                <td>{{dateFormatter(indexElement.date)}}</td>
                <td>{{indexElement.COD}}</td>
                <td>{{dateFormatter(indexElement.dispatchedDate)}}</td>
                <td>{{dateFormatter(indexElement.returnDate)}}</td>
                <td>{{getUserName(indexElement.confirmAgent)}}</td>
                <td>{{indexElement.reasonToReturn}}</td>
                <td>{{indexElement.checkBy}}</td>
                <td>{{indexElement.mistakeBy}}</td>
                <td>{{indexElement.authorizedBy}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Date Or Date-Range</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [dayTemplate]="t" [displayMonths]="1" ngModel>
            </ngb-datepicker>

            <ng-template #t let-date="date" let-focused="focused">
                <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.focused]="focused"
                      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                      class="custom-day">
                    {{ date.day }}
                </span>
            </ng-template>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton"
                    type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

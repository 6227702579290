<div class="row">
  <!-- Column -->
  <div class="col-lg-4 col-xlg-3 col-md-5">
    <div class="card">
      <div class="card-body">
        <div class="m-t-30 text-center">
          <img src="{{getImageUrl()}}" (click)="openWidget()" style="cursor: pointer;" class="rounded-circle" width="150" />
          <h4 class="card-title m-t-10">{{userData.empName}}</h4>
          <h6 class="card-subtitle"></h6>
          <div class="row text-center justify-content-md-center">
            <div class="col-4">
              <a href="javascript:void(0)" class="link">
                <i class="icon-people m-r-10"></i>
                Type : <b>{{userData.empPost}}</b>
              </a>
            </div>
            <div class="col-4">
              <a href="javascript:void(0)" class="link">
                <i class="icon-picture m-r-10"></i>
                Status : <b>{{userData.empStatus}}</b>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr> </div>
      <div class="card-body">
        <small class="text-muted">Email address </small>
        <h6>{{userData.empEmail}}</h6>
        <small class="text-muted p-t-30 db">Phone</small>
        <h6>{{userData.empPhone}}</h6>
      </div>
    </div>
  </div>
  <!-- Column -->
  <!-- Column -->
  <div class="col-lg-8 col-xlg-9 col-md-7">
    <div class="card">
      <ngb-tabset type="pills" class="custom-pills">
        <ngb-tab title="Update Profile">
          <ng-template ngbTabContent>
            <div class="card-body">
                <div class="form-group">
                  <label class="col-md-12">Full Name</label>
                  <div class="col-md-12">
                    <input [(ngModel)]="fullName" class="form-control" ngModel type="text" value="{{fullName}}"/>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-12">Email</label>
                  <div class="col-md-12">
                    <input type="email" [(ngModel)]="email" placeholder="username@gmail.com" disabled class="form-control form-control-line" ngModel value="{{email}}">
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-12">Phone</label>
                  <div class="col-md-12">
                    <input type="text" [(ngModel)]="phone" class="form-control form-control-line" value="{{phone}}" ngModel>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-success" (click)="updateEmployeeDetails()" >Update Profile</button>
                  </div>
                </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Update Password">
          <ng-template ngbTabContent>
            <div class="card-body">
              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Email</label>
                <input [(ngModel)]="empEmail" class="form-control" disabled ngModel type="text"
                       value="{{empEmail}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Password</label>
                <input [(ngModel)]="empPassword" class="form-control" ngModel type="password" value="{{empPassword}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Re-Type Password</label>
                <input [(ngModel)]="reEmpPassword" class="form-control" ngModel type="password" value="{{reEmpPassword}}"/>
              </div>
                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-success" (click)="updatePassword()" >Update Password</button>
                  </div>
                </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <!-- Column -->
</div>

<notifier-container></notifier-container>

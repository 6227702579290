<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Waybill Id</th>
                <th scope="col">Order Number</th>
                <th scope="col">Receiver Name</th>
                <th scope="col">Delivery Address</th>
                <th scope="col">District Name</th>
                <th scope="col">Receiver Phone</th>
                <th scope="col">COD</th>
                <th scope="col">Description</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of rows">
                <td>{{indexElement.COD}}</td>
                <td>{{indexElement.orderNumber}}</td>
                <td>{{indexElement.customerName}}</td>
                <td>{{indexElement.addressNumber}},{{indexElement.addressFirstLine}},{{indexElement.addressSecondLine}}
                  ,{{indexElement.city}}</td>
                <td>{{indexElement.city}}</td>
                <td>{{indexElement.phoneNumber}}/{{indexElement.fixedLine}}</td>
                <td>{{indexElement.totalAmount}}.00</td>
                <td>Batik Dress</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">

    <div class="card">
      <div class="card-body mainBlockColor warrenty">
        <br/>
        <h1 class="orderDetails">Total Count : {{rows?.length}}</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Delivery Date</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [(ngModel)]="selectedDate" [displayMonths]="1"
                            ngModel>
            </ngb-datepicker>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton"
                    type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

import { Component, OnInit } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ServiceService} from '../../service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userName: any;
  empEmail: any;
  userImageProfileImage: any;
  userData:any =[];

  fullName: any;
  email: any;
  phone: any;
  employeePost: any;
  activeStatus: any;
  empPassword: any;
  reEmpPassword: any;
  private notifier: NotifierService;

  constructor(private activatedRoute: ActivatedRoute,private serviceClass: ServiceService, notifier: NotifierService) {
    this.notifier = notifier;
    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
      this.serviceClass.getSingleEmployeeData(dataValues.empEmail).subscribe(
        data => {
          const helper = new JwtHelperService();
          const dataValues = helper.decodeToken(data).rows[0];
          this.userData = dataValues;
          this.userName = dataValues.empName;
          this.fullName = dataValues.empName;
          this.empEmail = dataValues.empEmail;
          this.email = dataValues.empEmail;
          this.phone = dataValues.empPhone;
          this.userImageProfileImage = dataValues.imageThumbnail;
          this.activeStatus = dataValues.empStatus;
          this.employeePost = dataValues.empPost;
        }
      );
  }

  updatePassword() {
    if (this.empEmail != "none") {
      if(this.empPassword == this.reEmpPassword) {
        this.serviceClass.changePassword(this.empPassword, this.empEmail).subscribe(
          data => {
            if (data == true) {
              this.empPassword = "";
              this.showNotification("success", "Password Updated Successfully!");
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
        );
      }else{
        this.showNotification("error","Please type same passwords to continue!")
      }
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  getImageUrl(){
    if(this.userImageProfileImage == null){
      return "../../../../assets/images/users/userProfileIcon.png";
    }else{
      return this.userImageProfileImage;
    }
  }

  ngOnInit() {
  }

  updateEmployeeDetails() {

    if (this.email != "none") {
      this.serviceClass.updateEmployeeDetails(this.fullName, this.email, this.phone, this.employeePost, this.activeStatus).subscribe(
        data => {
          if (data == true) {
            this.serviceClass.getAllUsers().subscribe(
              data => {
                this.serviceClass.getSingleEmployeeData(this.empEmail).subscribe(
                  data => {
                    const helper = new JwtHelperService();
                    const dataValues = helper.decodeToken(data).rows[0];
                    this.userData = dataValues;
                    this.userName = dataValues.empName;
                    this.fullName = dataValues.empName;
                    this.empEmail = dataValues.empEmail;
                    this.email = dataValues.empEmail;
                    this.phone = dataValues.empPhone;
                    this.userImageProfileImage = dataValues.imageThumbnail;
                    this.activeStatus = dataValues.empStatus;
                    this.employeePost = dataValues.empPost;
                  }
                );
              }
            );
            this.showNotification("success", "Details updated Successfully!");
          } else {
            this.showNotification("error", "There is a error. Please check and try again!");
          }
        }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

// @ts-ignore
  cloudinary = cloudinary.createUploadWidget({
      cloudName: 'buyzone-lk',
      maxImageFileSize:1500000,
      clientAllowedFormats:["png","gif", "jpeg"],
      showSkipCropButton:false,
      defaultSource:"camera",
      cropping:true,
      thumbnailTransformation:[{ width: 200, height: 200, crop: 'fit' }],
      maxFiles:1,
      croppingAspectRatio:1,
      croppingDefaultSelectionRatio:1,
      uploadPreset: 'Buyzone_lk_sjbf3455jerl345flcenf233v3ner4534ilfj'}, (error, result) => {
      if(result.info.thumbnail_url != null) {
        this.serviceClass.updateProfilePicture(this.empEmail, result.info.thumbnail_url).subscribe(
          data => {
            if(data != false) {
              localStorage.setItem('user_details', data);
              const helper = new JwtHelperService();
              const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
              this.userImageProfileImage = dataValues.imageThumbnail;
            }
          }
        );
      }
    }
  )

  openWidget() {
    this.cloudinary.open();
  }
}

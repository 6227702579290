<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">ref</th>
                <th scope="col">Date</th>
                <th scope="col">COD</th>
                <th scope="col">Item</th>
                <th scope="col">Issue</th>
                <th scope="col">Item Checked By</th>
                <th scope="col">Approved Date</th>
                <th scope="col">Approval Status</th>
                <th scope="col">Approved By</th>
              </tr>
              </thead>
              <tbody>
              <tr
                (click)="getWarrantyDetails(indexElement.id,indexElement.codNumber,indexElement.itemId1,indexElement.customerComplaintAbout,indexElement.approvalStatus)"
                *ngFor="let indexElement of rows"
              >
                <td>{{indexElement.id}}</td>
                <td>{{formatDate(indexElement.addedTime)}}</td>
                <td>{{indexElement.codNumber}}</td>
                <td>{{getItemName(indexElement.itemId1)}}</td>
                <td>{{indexElement.customerComplaintAbout}}</td>
                <td>{{indexElement.itemCheckBy}}</td>
                <td>{{formatDate(indexElement.approvedTime)}}</td>
                <td>{{indexElement.approvalStatus}}</td>
                <td>{{indexElement.approvedBy}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card">
      <div class="card-body">
        <mat-accordion class="example-headers-align">

          <mat-expansion-panel (opened)="setAccordionId(0)" [expanded]="noteId === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-plus"></i>&nbsp;&nbsp; Add Warranty</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">COD Number</label>
              <input [(ngModel)]="codNumber" class="form-control" ngModel type="text" value="{{codNumber}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Item</label>
              <ng-autocomplete
                (selected)='setSelectBoxValues("item1", $event)'
                [data]="phoneDetailsJsonArray"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                [placeHolder]="itemName"
                [searchKeyword]="keyword"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Issue</label>
              <input [(ngModel)]="complaint" class="form-control" ngModel type="text" value="{{complaint}}"/>
            </div>

            <div class="col-lg-12">
              <button (click)="addWarranty()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Add Warranty
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(1)" [expanded]="noteId === 1" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-refresh"></i>&nbsp;&nbsp; Update Warranty</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Ref Id</label>
              <input [(ngModel)]="itemId" class="form-control" ngModel type="text" value="{{itemId}}" disabled/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">COD Number</label>
              <input [(ngModel)]="codNumber" class="form-control" ngModel type="text" value="{{codNumber}}" disabled/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Item</label>
              <ng-autocomplete
                (selected)='setSelectBoxValues("item1", $event)'
                [data]="phoneDetailsJsonArray"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                [placeHolder]="itemName"
                [searchKeyword]="keyword"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Issue</label>
              <input [(ngModel)]="complaint" class="form-control" ngModel type="text" value="{{complaint}}"/>
            </div>

            <div class="col-lg-12">
              <button (click)="updateWarranty()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Update Warranty
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- User Profile-->
    <li>
      <!-- User Profile-->
      <div class="user-profile m-t-20" ngbDropdown>
        <div class="user-pic">
          <img alt="users" class="rounded-circle img-fluid" style="cursor: pointer;" (click)="routeToSingleOrderPage()" src="{{getImageUrl()}}"/>
        </div>
        <div class="user-content hide-menu m-t-10">
          <h5 class="m-b-10 user-name font-medium">{{userName}}</h5>
          <!--          <a aria-expanded="false" aria-haspopup="true" class="btn btn-circle btn-sm m-r-5" data-toggle="dropdown"-->
          <!--             href="javascript:void(0)"-->
          <!--             id="Userdd"-->
          <!--             ngbDropdownToggle role="button">-->
          <!--            <i class="ti-settings"></i>-->
          <!--          </a>-->
          <a (click)="logout()" class="btn btn-circle btn-sm" href="javascript:void(0)" title="Logout">
            <i class="ti-power-off"></i>
          </a>
          <!--          <div aria-labelledby="Userdd" class="animated flipInY" ngbDropdownMenu>-->
          <!--            <a class="dropdown-item" href="javascript:void(0)">-->
          <!--              <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>-->
          <!--            <a class="dropdown-item" href="javascript:void(0)">-->
          <!--              <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>-->
          <!--            <a class="dropdown-item" href="javascript:void(0)">-->
          <!--              <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>-->
          <!--            <div class="dropdown-divider"></div>-->
          <!--            <a class="dropdown-item" href="javascript:void(0)">-->
          <!--              <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>-->
          <!--            <div class="dropdown-divider"></div>-->
          <!--            <a class="dropdown-item" href="javascript:void(0)">-->
          <!--              <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>-->
          <!--          </div>-->
        </div>
      </div>
      <!-- End User Profile-->
    </li>
    <!-- First level menu -->
    <li *ngFor="let sidebarnavItem of sidebarnavItems" [class.selected]="showMenu === sidebarnavItem.title"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
        class="sidebar-item">
      <div *ngIf="sidebarnavItem.extralink === true" class="nav-small-cap">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{sidebarnavItem.title}}</span>
      </div>
      <a (click)="addExpandClass(sidebarnavItem.title)"
         *ngIf="!sidebarnavItem.extralink;"
         [ngClass]="[sidebarnavItem.class]" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
         [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
         class="sidebar-link waves-effect waves-dark">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{sidebarnavItem.title}}</span>
      </a>
      <!-- Second level menu -->
      <ul *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }"
          aria-expanded="false"
          class="collapse first-level">
        <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
            [class.active]="showSubMenu === sidebarnavSubItem.title"
            [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'"
            class="sidebar-item">
          <a (click)="addActiveClass(sidebarnavSubItem.title)" *ngIf="!sidebarnavSubItem.extralink;"
             [ngClass]="[sidebarnavSubItem.class]"
             [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
             [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
             class="sidebar-link">
            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
            <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
          </a>
          <!-- Third level menu -->
          <ul *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }"
              aria-expanded="false"
              class="collapse second-level">
            <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" [ngClass]="[sidebarnavSubsubItem.class]"
                class="sidebar-item" routerLinkActive="active">
              <a *ngIf="!sidebarnavSubsubItem.extralink;"
                 [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'"
                 [routerLink]="[sidebarnavSubsubItem.path]"
                 class="sidebar-link">
                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>

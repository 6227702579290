import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceService} from '../../service.service';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-backup-download',
  templateUrl: './backup-download.component.html',
  styleUrls: ['./backup-download.component.css']
})
export class BackupDownloadComponent implements OnInit {
  private notifier: NotifierService;

  constructor(private spinner: NgxSpinnerService, private serviceClass: ServiceService, notifier: NotifierService, private route: ActivatedRoute, private router: Router, private activatedRoute: ActivatedRoute) {
    this.notifier = notifier;
  }

  ngOnInit() {
  }

  downloadBackup() {
    window.open('http://backup.buyzone.lk','','width=600,height=600,resizable=no');
  }
}

import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from 'xlsx';
import {JwtHelperService} from '@auth0/angular-jwt';

// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker


@Component({
  selector: 'app-warranty-manager',
  templateUrl: './warranty-manager.component.html',
  styleUrls: ['./warranty-manager.component.css']
})
export class WarrantyManagerComponent implements OnInit {

  // This is for the range date picker
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedEmployeeName: any = "All";
  currentSelectedEmployeeEmail: any = "All";
  totalOrders: any = 0;
  confirmedOrders: any = 0;
  employeeName: any = "none";
  employeeEmail: any = "none";
  empPhone: any;
  activeStatus: any;
  employeePost: any;
  empPassword: any;
  addOrdersAccess: any;
  confirmOrderAccess: any;
  deliveredOrderAccess: any;
  returnOrderAccess: any;
  canceledOrderAccess: any;
  noteId: number;
  addOrderAccess: any;
  codNumber: any;
  updatingId: any;
  itemName: any;
  itemId: any;
  complaint: any;
  agentEmail: any = 'sasa.uoc@gmail.com';
  warrantyStatus:any;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    const helper = new JwtHelperService();
    this.agentEmail = helper.decodeToken(localStorage.getItem('user_details')).rows[0]["empEmail"];
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updateDataValues();
  }

  updateDataValues () {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );
    this.serviceClass.getAllWarranty().subscribe(
      data => {
        this.rows = data;
      }
    );
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - All Items.xlsx");
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    if(dateAndTime == null){
      return "-";
    }
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    // tslint:disable-next-line:semicolon
    before(date, this.hoveredDate);

  isInside = date => after(date, this.fromDate) && before(date, this.toDate);

  isFrom = date => equals(date, this.fromDate);

  isTo = date => equals(date, this.toDate);

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }
  setSelectBoxValues(dataType: any, item) {
    this.itemId = item.deviceId;
    this.itemName = item.deviceName;
  }

  timeFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  addWarranty() {
    if (this.codNumber != undefined) {
      this.serviceClass.addWarrantyOnWarrantyTable(this.agentEmail,this.codNumber, this.itemId, this.complaint).subscribe(
        data => {
          if (data == true) {
            this.showNotification("success", "Warranty added successfully!");
          } else {
            this.showNotification("error", "There is a error. Please check and try again!");
          }
        }
      );
    } else {
      this.showNotification("error", "COD Number is required!");
    }
  }

  setAccordionId(index: number) {
    this.noteId = index;
  }

  getWarrantyDetails(id:any,codNumber: any, itemId1: any, customerComplaintAbout: any,status:any) {
    this.codNumber = codNumber;
    this.itemId = itemId1;
    this.itemName = this.getItemName(itemId1);
    this.complaint = customerComplaintAbout;
    this.updatingId = id;
    this.activeStatus = status;
    this.setAccordionId(2);
  }

  updateWarranty() {
    this.serviceClass.updateWarrantyDetails(this.updatingId,this.agentEmail,this.codNumber, this.itemId, this.complaint).subscribe(
      data => {
        if (data == true) {
          this.codNumber = null;
          this.itemId = null;
          this.itemName = null;
          this.complaint = null;
          this.updatingId = null;
          this.updateDataValues();
          this.setAccordionId(-1);
          this.showNotification("success", "Data updated successfully!");
        } else {
          this.showNotification("error", "There is a error. Please check and try again!");
        }
      }
    );
  }

  updateWarrantyStatus() {
    if(this.warrantyStatus == "approved" || this.warrantyStatus == "pending") {
      this.serviceClass.updateWarrantyStatus(this.agentEmail, this.updatingId, this.warrantyStatus, this.codNumber).subscribe(
        data => {
          if (data == true) {
            this.updateDataValues();
            this.setAccordionId(-1);
            this.showNotification("success", "Warranty status updated!");
          } else {
            this.showNotification("error", "There is a error. Please check and try again!");
          }
        }
      );
    } else {
      this.showNotification("error","Please select status!")
    }
  }
}

import {Routes} from '@angular/router';
import {MainOrdersComponent} from "./OrderManagement/main-orders/main-orders.component";
import {UpdateOrderComponent} from "./OrderManagement/update-order/update-order.component";
import {AddOrderComponent} from "./OrderManagement/add-order/add-order.component";
import {Order_creationComponent} from "./Reports/order_creation/order_creation.component";
import {OrderConfirmationComponent} from "./Reports/order-confirmation/order-confirmation.component";
import {OrderPendingComponent} from "./Reports/order-pending/order-pending.component";
import {OrderDeliveredComponent} from "./Reports/order-delivered/order-delivered.component";
import {OrderReturnedComponent} from "./Reports/order-returned/order-returned.component";
import {OrderCanceledComponent} from "./Reports/order-canceled/order-canceled.component";
import {DispatchBaseonDeliveryTypeComponent} from "./Reports/dispatch-baseon-delivery-type/dispatch-baseon-delivery-type.component";
import {DeliveryBasedonDateComponent} from "./Reports/delivery-basedon-date/delivery-basedon-date.component";
import {OrderAccuracyComponent} from "./Reports/order-accuracy/order-accuracy.component";
import {ItemWiseComponent} from "./Reports/item-wise/item-wise.component";
import {UploadingSheetComponent} from "./Reports/uploading-sheet/uploading-sheet.component";
import {OrderDetailsStickerPasteComponent} from "./Reports/order-details-sticker-paste/order-details-sticker-paste.component";
import {AddressStickerPrintComponent} from "./Reports/address-sticker-print/address-sticker-print.component";
import {DispatchDateCountComponent} from "./Reports/dispatch-to-delivered-date-count/dispatch-date-count.component";
import {DispatchToReturnDateCountComponent} from "./Reports/dispatch-to-return-date-count/dispatch-to-return-date-count.component";
import {ReturnReportForReturnedDateComponent} from "./Reports/return-report-for-returned-date/return-report-for-returned-date.component";
import {CashAcceptedCheckingComponent} from "./cash-accepted-checking/cash-accepted-checking.component";
import {CheckProntoParcelAcceptComponent} from "./Reports/check-pronto-parcel-accept/check-pronto-parcel-accept.component";
import {CheckReturnOrdersComponent} from "./Reports/check-return-orders/check-return-orders.component";
import {AuthGuard} from '../auth.guard';
import {WarrantyManagerComponent} from './warranty-manager/warranty-manager.component';
import {ProfileComponent} from './profile/profile.component';
import {CategoryManagerComponent} from '../Admin/ChatBot/category-manager/category-manager.component';
import {ItemsManagerComponent} from '../Admin/ChatBot/items-manager/items-manager.component';
import {ManufacturerManagerComponent} from '../Admin/ChatBot/manufacturer-manager/manufacturer-manager.component';
import {PromotionManagerComponent} from '../Admin/ChatBot/promotion-manager/promotion-manager.component';
import {PromotionManagerComponentUser} from './ChatBot/promotion-manager/promotion-manager.component';
import {ManufacturerManagerComponentUser} from './ChatBot/manufacturer-manager/manufacturer-manager.component';
import {ItemsManagerComponentUser} from './ChatBot/items-manager/items-manager.component';
import {CategoryManagerComponentUser} from './ChatBot/category-manager/category-manager.component';
import {DisableChatComponent} from './ChatBot/disable-chat/disable-chat.component';
import {DispatchTrackerComponent} from './OrderManagement/dispatch-tracker/dispatch-tracker.component';

export const DashboardRoutes: Routes = [
  {
    path: 'main',
    component: MainOrdersComponent,
    data: {
      title: 'Main Page',
      urls: [
        {title: 'Main Page', url: '/main'},
        {title: 'Main Page'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'order_details',
    component: UpdateOrderComponent,
    data: {
      title: 'Order Details',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Order Details'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'add_order',
    component: AddOrderComponent,
    data: {
      title: 'Add order',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Add order'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_creation',
    component: Order_creationComponent,
    data: {
      title: 'Order Creation Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'General Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_confirmation',
    component: OrderConfirmationComponent,
    data: {
      title: 'Order Confirmation Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Confirmation Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_pending',
    component: OrderPendingComponent,
    data: {
      title: 'Order Pending Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Pending Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_delivered',
    component: OrderDeliveredComponent,
    data: {
      title: 'Order Delivered Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Delivered Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_returned',
    component: OrderReturnedComponent,
    data: {
      title: 'Order Returned Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Returned Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_canceled',
    component: OrderCanceledComponent,
    data: {
      title: 'Order Canceled Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Canceled Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/dispatch-with-deliveryType',
    component: DispatchBaseonDeliveryTypeComponent,
    data: {
      title: 'Dispatch Base On Delivery Type Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Dispatch Base On Delivery Type Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/delivery-baseOn-date',
    component: DeliveryBasedonDateComponent,
    data: {
      title: 'Delivery Base On Date',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Delivery Base On Date'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/accuracy-rate',
    component: OrderAccuracyComponent,
    data: {
      title: 'Success Rate Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Success Rate Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/item-wise',
    component: ItemWiseComponent,
    data: {
      title: 'Item Wise Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Item Wise Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/pronto-uploadSheet',
    component: UploadingSheetComponent,
    data: {
      title: 'Uploading Sheet Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Uploading Sheet Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/all-oderDetails',
    component: OrderDetailsStickerPasteComponent,
    data: {
      title: 'All Order Details',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'All Order Details'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/dispatch-to-delivered-date-count',
    component: DispatchDateCountComponent,
    data: {
      title: 'Dispatch To Delivered Date Count',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Dispatch To Delivered Date Count'}
      ]
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'order/dispatch-tracker',
    component: DispatchTrackerComponent,
    data: {
      title: 'Dispatch Tracker',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch Tracker'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/dispatch-to-return-date-count',
    component: DispatchToReturnDateCountComponent,
    data: {
      title: 'Dispatch To Returned Date Count',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Dispatch To Returned Date Count'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'dispatch/address-print',
    component: AddressStickerPrintComponent,
    data: {
      title: 'Address Print',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Address Print'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'return/based-on-returned-date',
    component: ReturnReportForReturnedDateComponent,
    data: {
      title: 'Return Day Report',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Return Day Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'checker/pronto-cash',
    component: CashAcceptedCheckingComponent,
    data: {
      title: 'Cash Accept Checker',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Cash Accept Checker'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'checker/pronto-parcel-accept',
    component: CheckProntoParcelAcceptComponent,
    data: {
      title: 'Parcel Accept Checker',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Parcel Accept Checker'}
      ]
    },
    canActivate:[AuthGuard]
  }

  ,

  {
    path: 'report/check-return-orders',
    component: CheckReturnOrdersComponent,
    data: {
      title: 'Check Return',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Check Return'}
      ]
    },
    canActivate:[AuthGuard]
  }

  ,

  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Profile',
      urls: [
        {title: 'Main Page', url: '/user/main'},
        {title: 'Profile'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/warranty-manager',
    component: WarrantyManagerComponent,
    data: {
      title: 'Warranty Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Warranty Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/category-manager',
    component: CategoryManagerComponentUser,
    data: {
      title: 'Bot Category Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Category Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/items-manager',
    component: ItemsManagerComponentUser,
    data: {
      title: 'Bot Items Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Items Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/manufacturer-manager',
    component: ManufacturerManagerComponentUser,
    data: {
      title: 'Bot Manufacturer Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Manufacturer Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/promotion-manager',
    component: PromotionManagerComponentUser,
    data: {
      title: 'Bot Promotion Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Promotion Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/disableChat',
    component: DisableChatComponent,
    data: {
      title: 'Disable Auto Reply On Bot',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Disable Auto Reply On Bot'}
      ]
    },
    canActivate:[AuthGuard]
  }


];

<div class="row" id="backgroundColor">
  <div class="col-lg-9">

    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered " style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Qty</th>
                <th scope="col">Note</th>
              </tr>
              </thead>
              <tbody class="tBodyContent">
              <tr *ngIf="qty1 > 0">
                <td>{{item1}}</td>
                <td>{{qty1}}</td>
                <td>{{specialNote1}}</td>
              </tr>
              <tr *ngIf="qty2 > 0">
                <td>{{item2}}</td>
                <td>{{qty2}}</td>
                <td>{{specialNote2}}</td>
              </tr>
              <tr *ngIf="qty3 > 0">
                <td>{{item3}}</td>
                <td>{{qty3}}</td>
                <td>{{specialNote3}}</td>
              </tr>
              <tr *ngIf="qty4 > 0">
                <td>{{item4}}</td>
                <td>{{qty4}}</td>
                <td>{{specialNote4}}</td>
              </tr>
              <tr *ngIf="qty5 > 0">
                <td>{{item5}}</td>
                <td>{{qty5}}</td>
                <td>{{specialNote5}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="customerName != undefined">
      <div class="col-8">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <tbody>
            <tr>
              <td>Special Notes</td>
              <td>{{specialNotes}}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{customerName}}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{addressNumber}} , {{addressFirstLine}} , {{addressSecondLine}} , {{city}}</td>
            </tr>
            <tr>
              <td>Contact Details</td>
              <td>{{phoneNumber}} / {{telephoneNumber}}</td>
            </tr>
            <tr>
              <td>Delivery Date</td>
              <td>{{formatDate(deliveryDate)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-4"></div>
    </div>

  </div>

  <div class="col-lg-3">
    <div *ngIf="customerName != undefined" class="card">
      <div class="card-body mainBlockColor {{deliveryStatus}}">
        <br/>
        <h1 class="orderDetails">{{deliveryStatus}}</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form #f="ngForm" (ngSubmit)="dispatchTheOrder(f)" novalidate>
          <label for="codNumber">Enter COD Number : </label>
          <input class="form-control" id="codNumber" name="codNumber" ngModel placeholder="Search &amp; enter"
                 type="text"/>
        </form>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

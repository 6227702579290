import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {ServiceService} from '../../service.service';

@Component({
  selector: 'app-cod-update',
  templateUrl: './cod-update.component.html',
  styleUrls: ['./cod-update.component.css']
})
export class CodUpdateComponent implements OnInit {
  selectedDate: any;
  private notifier: NotifierService;
  prefix: any;
  digitCount: any;
  fromNumber: any;
  toNumber: any;

  constructor(private serviceClass: ServiceService,private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.serviceClass.getCurrentCodDetails().subscribe(
      data => {
        this.prefix="sasa";
        // @ts-ignore
        if(data.length > 0 ){
          this.prefix = data[0].Prefix;
          this.digitCount = data[0].digitCount;
          this.fromNumber = data[0].currentCod;
          this.toNumber = data[0].maxAllowedNumber;
        }

      }
    );
  }

  updateCodNumbers() {

    this.serviceClass.updateCodDetails(this.prefix,this.digitCount,this.fromNumber,this.toNumber).subscribe(
      data => {
        if(data.affectedRows > 0){
          this.showNotification("success","Successfully updated")
        }else{
          this.showNotification("error","Updating Failed!")
        }
      }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
}

<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <!--    <li class="nav-item d-none d-md-block">-->
  <!--      <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" data-sidebartype="mini-sidebar"-->
  <!--         href="javascript:void(0)">-->
  <!--        <i class="sl-icon-menu font-20"></i>-->
  <!--      </a>-->
  <!--    </li>-->
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->
  <!--    <li [autoClose]="false" class="nav-item mega-dropdown" ngbDropdown>-->
  <!--      <a aria-expanded="false" aria-haspopup="true" class="nav-link waves-effect waves-dark" data-toggle="dropdown"-->
  <!--         href="javascript:void(0)" ngbDropdownToggle>-->
  <!--        <i class="ti-gift font-20"></i>-->
  <!--      </a>-->
  <!--      <div class="" ngbDropdownMenu>-->
  <!--        <div class="mega-dropdown-menu row">-->
  <!--          <div class="col-lg-3 col-xlg-2 m-b-30">-->
  <!--            <h5 class="m-b-20">Carousel</h5>-->
  <!--            &lt;!&ndash; CAROUSEL &ndash;&gt;-->
  <!--            <ngb-carousel>-->
  <!--              <ng-template ngbSlide>-->
  <!--                <img alt="Random first slide" class="img-fluid" src="../../../../assets/images/big/img1.jpg">-->
  <!--                <div class="carousel-caption">-->
  <!--                  <h3 class="text-white font-bold">First slide label</h3>-->
  <!--                </div>-->
  <!--              </ng-template>-->
  <!--              <ng-template ngbSlide>-->
  <!--                <img alt="Random second slide" class="img-fluid" src="../../../../assets/images/big/img2.jpg">-->
  <!--                <div class="carousel-caption">-->
  <!--                  <h3 class="text-white font-bold">Second slide label</h3>-->
  <!--                </div>-->
  <!--              </ng-template>-->
  <!--              <ng-template ngbSlide>-->
  <!--                <img alt="Random third slide" class="img-fluid" src="../../../../assets/images/big/img3.jpg">-->
  <!--                <div class="carousel-caption">-->
  <!--                  <h3 class="text-white font-bold">Third slide label</h3>-->
  <!--                </div>-->
  <!--              </ng-template>-->
  <!--            </ngb-carousel>-->
  <!--            &lt;!&ndash; End CAROUSEL &ndash;&gt;-->
  <!--          </div>-->
  <!--          <div class="col-lg-3 m-b-30">-->
  <!--            <h5 class="m-b-20">Accordion</h5>-->
  <!--            &lt;!&ndash; Accordian &ndash;&gt;-->
  <!--            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="accordion nav-accordion">-->
  <!--              <ngb-panel title="Simple">-->
  <!--                <ng-template ngbPanelContent>-->
  <!--                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.-->
  <!--                </ng-template>-->
  <!--              </ngb-panel>-->
  <!--              <ngb-panel>-->
  <!--                <ng-template ngbPanelTitle>-->
  <!--                                  <span>&#9733;-->
  <!--                                      <b>Fancy</b> title &#9733;</span>-->
  <!--                </ng-template>-->
  <!--                <ng-template ngbPanelContent>-->
  <!--                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.-->
  <!--                </ng-template>-->
  <!--              </ngb-panel>-->
  <!--              <ngb-panel [disabled]="true" title="Disabled">-->
  <!--                <ng-template ngbPanelContent>-->
  <!--                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.-->
  <!--                </ng-template>-->
  <!--              </ngb-panel>-->
  <!--            </ngb-accordion>-->
  <!--          </div>-->
  <!--          <div class="col-lg-3  m-b-30">-->
  <!--            <h5 class="m-b-20">Contact Us</h5>-->
  <!--            &lt;!&ndash; Contact &ndash;&gt;-->
  <!--            <form>-->
  <!--              <div class="form-group">-->
  <!--                <input class="form-control" id="exampleInputname1" placeholder="Enter Name" type="text"></div>-->
  <!--              <div class="form-group">-->
  <!--                <input class="form-control" placeholder="Enter email" type="email"></div>-->
  <!--              <div class="form-group">-->
  <!--                <textarea class="form-control" id="exampleTextarea" placeholder="Message" rows="3"></textarea>-->
  <!--              </div>-->
  <!--              <button class="btn btn-info" type="submit">Submit</button>-->
  <!--            </form>-->
  <!--          </div>-->
  <!--          <div class="col-lg-3 col-xlg-4 m-b-30">-->
  <!--            <h5 class="m-b-20">List style</h5>-->
  <!--            &lt;!&ndash; List style &ndash;&gt;-->
  <!--            <ul class="list-style-none">-->
  <!--              <li>-->
  <!--                <a href="javascript:void(0)">-->
  <!--                  <i class="fa fa-check text-success"></i> You can give link</a>-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                <a href="javascript:void(0)">-->
  <!--                  <i class="fa fa-check text-success"></i> Give link</a>-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                <a href="javascript:void(0)">-->
  <!--                  <i class="fa fa-check text-success"></i> Another Give link</a>-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                <a href="javascript:void(0)">-->
  <!--                  <i class="fa fa-check text-success"></i> Forth link</a>-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                <a href="javascript:void(0)">-->
  <!--                  <i class="fa fa-check text-success"></i> Another fifth link</a>-->
  <!--              </li>-->
  <!--            </ul>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </li>-->
  <!--   ============================================================== -->
  <!--   End mega menu -->
  <!--   ============================================================== -->
  <!--   ============================================================== -->
  <!--   Comment -->
  <!--   ============================================================== -->
  <!--    <li class="nav-item" ngbDropdown>-->
  <!--      <a aria-expanded="false" aria-haspopup="true" class="nav-link waves-effect waves-dark" data-toggle="dropdown"-->
  <!--         href="javascript:void(0)" ngbDropdownToggle>-->
  <!--        <i class="ti-bell font-20"></i>-->

  <!--      </a>-->
  <!--      <div class="mailbox" ngbDropdownMenu>-->
  <!--              <span class="with-arrow">-->
  <!--                  <span class="bg-primary"></span>-->
  <!--              </span>-->
  <!--        <ul class="list-style-none">-->
  <!--          <li>-->
  <!--            <div class="drop-title bg-primary text-white">-->
  <!--              <h4 class="m-b-0 m-t-5">4 New</h4>-->
  <!--              <span class="font-light">Notifications</span>-->
  <!--            </div>-->
  <!--          </li>-->
  <!--          <li>-->
  <!--            <div [perfectScrollbar]="config" class="message-center notifications">-->
  <!--              &lt;!&ndash; Message &ndash;&gt;-->
  <!--              <a *ngFor="let notification of notifications" class="message-item" href="javascript:void(0)">-->
  <!--                              <span class="btn btn-circle {{notification.btn}}">-->
  <!--                                  <i class="{{notification.icon}}"></i>-->
  <!--                              </span>-->
  <!--                <span class="mail-contnet">-->
  <!--                                  <h5 class="message-title">{{notification.title}}</h5>-->
  <!--                                  <span class="mail-desc">{{notification.subject}}</span>-->
  <!--                                  <span class="time">{{notification.time}}</span>-->
  <!--                              </span>-->
  <!--              </a>-->
  <!--            </div>-->
  <!--          </li>-->
  <!--          <li>-->
  <!--            <a class="nav-link text-center m-b-5" href="javascript:void(0);">-->
  <!--              <strong>Check all notifications</strong>-->
  <!--              <i class="fa fa-angle-right"></i>-->
  <!--            </a>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </li>-->
  <!--   ============================================================== -->
  <!--   End Comment -->
  <!--   ============================================================== -->
  <!--   ============================================================== -->
  <!--   Messages -->
  <!--   ============================================================== -->
  <li class="nav-item" ngbDropdown>
    <a (click)="updateDayOrderCount()" aria-expanded="false" aria-haspopup="true" class="nav-link waves-effect waves-dark"
       data-toggle="dropdown" id="2" ngbDropdownToggle>
      <i class="font-20 ti-arrow-circle-down"> {{currentTime}}</i>
    </a>
    <div aria-labelledby="2" class="mailbox" ngbDropdownMenu>
              <span class="with-arrow">
                  <span class="bg-danger"></span>
              </span>
      <ul class="list-style-none">
        <li>
          <div class="drop-title">
            <h3 class="m-b-0 m-t-5">Order Count</h3>
            <span class="font-light">(Current Day)</span>
          </div>
        </li>
        <li>
          <div [perfectScrollbar]="config" class="message-center message-body">
            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Pending</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                  <b>{{pendingCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Confirmed</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{confirmedCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Dispatched</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{dispatchedCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Delivered</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{deliveredCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Return</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{returnCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Canceled</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{canceledCount}}</b>
                </span>
            </a>

            <!-- Delivery Status Type -->
            <a class="message-item" href="javascript:void(0)">
                <span class="mail-contnet">
                    <h3 class="message-title"><b>Warranty Claim</b></h3>
                </span>
              <span class="btn btn-circle btn-dark">
                    <b>{{warrentyCount}}</b>
                </span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!--  &lt;!&ndash;   ============================================================== &ndash;&gt;-->
  <!--  &lt;!&ndash;   Messages &ndash;&gt;-->
  <!--  &lt;!&ndash;   ============================================================== &ndash;&gt;-->
  <!--  <li class="nav-item" ngbDropdown>-->
  <!--    <a aria-expanded="false" aria-haspopup="true" class="nav-link waves-effect waves-dark" data-toggle="dropdown"-->
  <!--       (click)="updateDayOrderCount()" id="2" ngbDropdownToggle>-->
  <!--      <i class="font-20 ti-arrow-circle-down"></i>-->
  <!--    </a>-->
  <!--    <div aria-labelledby="2" class="mailbox" ngbDropdownMenu>-->
  <!--              <span class="with-arrow">-->
  <!--                  <span class="bg-danger"></span>-->
  <!--              </span>-->
  <!--      <ul class="list-style-none">-->
  <!--        <li>-->
  <!--          <div class="drop-title">-->
  <!--            <h3 class="m-b-0 m-t-5">Order Count</h3>-->
  <!--            <span class="font-light">(Current Day)</span>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--        <li>-->
  <!--          <div [perfectScrollbar]="config" class="message-center message-body">-->
  <!--            &lt;!&ndash; Message &ndash;&gt;-->
  <!--            <a *ngFor="let mymessage of mymessages" class="message-item" href="javascript:void(0)">-->
  <!--                              <span class="user-img">-->
  <!--                                  <img alt="user" class="rounded-circle" src="{{mymessage.useravatar}}">-->
  <!--                                  <span class="profile-status {{mymessage.status}} pull-right"></span>-->
  <!--                              </span>-->
  <!--              <span class="mail-contnet">-->
  <!--                                  <h5 class="message-title">{{mymessage.from}}</h5>-->
  <!--                                  <span class="mail-desc">{{mymessage.subject}}</span>-->
  <!--                                  <span class="time">{{mymessage.time}}</span>-->
  <!--                              </span>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </li>-->
  <!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->
  <!--  &lt;!&ndash; End Messages &ndash;&gt;-->
  <!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->


</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <li class="nav-item search-box">
    <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
      <i class="ti-search"></i>
    </a>
    <form #f="ngForm" (ngSubmit)="routeToMainPage(f)" [ngClass]="{'show-search': showSearch}" class="app-search position-absolute"
          novalidate>

      <label for="searchBar"></label><input class="form-control" id="searchBar" name="searchKeyword"
                                            ngModel placeholder="Search &amp; enter" type="text"/>
      <a (click)="showSearch = !showSearch" class="srh-btn">
        <i class="ti-close font-16"></i>
      </a>
    </form>
  </li>
  <!-- ============================================================== -->
  <!-- create new -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" href="javascript:void(0)"
       id="navbarDropdown2" ngbDropdownToggle
       role="button">
      <i class="flag-icon flag-icon-lk font-18"></i>
    </a>
    <div aria-labelledby="navbarDropdown2" class="dropdown-menu-right" ngbDropdownMenu>
      <a class="dropdown-item">
        <i class="flag-icon flag-icon-lk"></i> English</a>
    </div>
  </li>

  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a aria-expanded="false" aria-haspopup="true" class="nav-link text-muted waves-effect waves-dark pro-pic"
       data-toggle="dropdown" href="javascript:void(0)"
       ngbDropdownToggle>
      <img alt="user" class="rounded-circle" src="{{getImageUrl()}}" width="31">
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
      <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
        <div class="">
          <img alt="user" class="img-circle" src="{{getImageUrl()}}" width="60">
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0">{{userName}}</h4>
          <p class=" m-b-0">{{emailAddress}}</p>
        </div>
      </div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="routeToSingleOrderPage()">
              <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
      <!--      <a class="dropdown-item" href="javascript:void(0)">-->
      <!--        <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>-->
      <!--      <a class="dropdown-item" href="javascript:void(0)">-->
      <!--        <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>-->
      <!--      <div class="dropdown-divider"></div>-->
      <!--      <a class="dropdown-item" href="javascript:void(0)">-->
      <!--        <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>-->
      <!--      <div class="dropdown-divider"></div>-->
      <a (click)="updatePrivileges()" class="dropdown-item" href="javascript:void(0)">
        <i class="sl-icon-refresh m-r-5 m-l-5"></i> Update Privileges</a>
      <a (click)="logout()" class="dropdown-item" href="javascript:void(0)">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>



<div class="a4SizePage">
  <div *ngFor="let indexElement of rows; index as i;">

    <div class="addressBlock">
      <h5 class="addressDetailsElements"><i class='icon far fa-user'></i>
        &nbsp;&nbsp;&nbsp;{{indexElement.customerName}}</h5>
      <h5 class="addressDetailsElements"><i class="icon fa fa-home"></i>
        &nbsp;&nbsp;&nbsp;{{indexElement.addressNumber}}</h5>
      <h5 class="addressDetailsElements">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{indexElement.addressFirstLine}}</h5>
      <h5 class="addressDetailsElements">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{indexElement.addressSecondLine}}</h5>
      <h5 class="addressDetailsElements">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{indexElement.city}}</h5>
      <h5 class="addressDetailsElements"><i class="icon fas fa-id-badge"></i>
        &nbsp;&nbsp;&nbsp;&nbsp;{{indexElement.COD}}</h5>
      <h5 class="addressDetailsElements"><i class="icon fa fa-phone"></i> &nbsp;&nbsp;&nbsp;{{indexElement.phoneNumber}}
        / {{indexElement.fixedLine}}</h5>
      <h5 class="addressDetailsElements"><i class="icon far fa-money-bill-alt"></i>
        &nbsp;&nbsp;{{indexElement.totalAmount}} LKR</h5>
      <hr>
      <h5 class="addressDetailsElements"><i class="icon far fa-envelope"></i>
        &nbsp;&nbsp;{{indexElement.envelope}}</h5>
      <hr>
      <ngx-barcode [bc-height]="60"
                   [bc-margin-bottom]="0"
                   [bc-margin-top]="0"
                   [bc-value]="indexElement.COD"
                   [bc-display-value]="true"
                   [bc-width]="1.8"></ngx-barcode>
      <hr>
      <h3 class="buyzoneNameStyle"><img alt="Logo" class="logoStyle" src="../../../assets/images/logo/wide-logo-black.png"/> </h3>
    </div>
  </div>
</div>

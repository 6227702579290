<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Post</th>
                <th scope="col">Active Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                (click)="updateEmployeeBlock(indexElement.empName,indexElement.empEmail,indexElement.empPhone,indexElement.empPost,indexElement.empStatus, indexElement.addOrdersAccess,indexElement.confirmOrderAccess,indexElement.deliveredOrderAccess,indexElement.returnOrderAccess,indexElement.canceledOrderAccess)"
                *ngFor="let indexElement of rows"
              >
                <td>{{indexElement.empName}}</td>
                <td>{{indexElement.empEmail}}</td>
                <td>{{indexElement.empPhone}}</td>
                <td>{{indexElement.empPost}}</td>
                <td>{{indexElement.empStatus}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card">
      <div class="card-body">
        <mat-accordion class="example-headers-align">

          <mat-expansion-panel (opened)="setAccordionId(0)" [expanded]="noteId === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-plus"></i>&nbsp;&nbsp; Add Employee</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Name</label>
              <input [(ngModel)]="employeeName" class="form-control" ngModel type="text" value="{{employeeName}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Email</label>
              <input [(ngModel)]="employeeEmail" class="form-control" ngModel type="text" value="{{employeeEmail}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Phone</label>
              <input [(ngModel)]="empPhone" class="form-control" ngModel type="text" value="{{empPhone}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Post</label>
              <select [(ngModel)]="employeePost" class="form-control" ngModel value="{{employeePost}}">
                <option value="admin">Admin</option>
                <option value="standard">Standard</option>
              </select>
            </div>

            <div class="col-lg-12">
              <button (click)="addEmployee()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Add Item
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(1)" [expanded]="noteId === 1" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-refresh"></i> &nbsp; Update Employee Details</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Name</label>
              <input [(ngModel)]="employeeName" class="form-control" ngModel type="text" value="{{employeeName}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Email</label>
              <input [(ngModel)]="employeeEmail" class="form-control" disabled ngModel type="text"
                     value="{{employeeEmail}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Phone</label>
              <input [(ngModel)]="empPhone" class="form-control" ngModel type="text" value="{{empPhone}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Post</label>
              <select [(ngModel)]="employeePost" class="form-control" ngModel value="{{employeePost}}">
                <option value="admin">Admin</option>
                <option value="standard">Standard</option>
              </select>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Active Status</label>
              <select [(ngModel)]="activeStatus" class="form-control" ngModel value="{{activeStatus}}">
                <option value="active">Active</option>
                <option value="in-active">In-Active</option>
              </select>
            </div>

            <div class="col-lg-12">
              <button (click)="updateEmployeeDetails()" class="btn btn-rounded btn-block btn-primary halfButton"
                      type="button">
                Update Item
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(2)" [expanded]="noteId === 2" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-settings"></i>&nbsp;&nbsp; Reset Password</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Email</label>
              <input [(ngModel)]="employeeEmail" class="form-control" disabled ngModel type="text"
                     value="{{employeeEmail}}"/>
            </div>

            <div class="col-12 m-t-10 m-b-10">
              <label class="form-control-label">Password</label>
              <input [(ngModel)]="empPassword" class="form-control" ngModel type="password" value="{{empPassword}}"/>
            </div>

            <div class="col-lg-12">
              <button (click)="updatePassword()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Update Password
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(3)" [expanded]="noteId === 3" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-user"></i>&nbsp;&nbsp; Access Management</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col-12 m-t-10 m-b-10">
              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Email</label>
                <input [(ngModel)]="employeeEmail" class="form-control" disabled ngModel type="text"
                       value="{{employeeEmail}}"/>
              </div>

              <div class="form-check">
                <input [(ngModel)]="addOrdersAccess" class="form-check-input" id="addOrdersAccess"
                       type="checkbox" value="{{addOrdersAccess}}"/>
                <label class="form-check-label" for="addOrdersAccess">
                  Add-Orders
                </label>
              </div>
              <div class="form-check">
                <input [(ngModel)]="confirmOrderAccess" class="form-check-input" id="confirmOrderAccess"
                       type="checkbox" value="{{confirmOrderAccess}}"/>
                <label class="form-check-label" for="confirmOrderAccess">
                  Confirm-Orders
                </label>
              </div>
              <div class="form-check">
                <input [(ngModel)]="deliveredOrderAccess" class="form-check-input" id="deliveredOrderAccess"
                       type="checkbox" value="{{deliveredOrderAccess}}"/>
                <label class="form-check-label" for="deliveredOrderAccess">
                  Delivered-Orders
                </label>
              </div>
              <div class="form-check">
                <input [(ngModel)]="returnOrderAccess" class="form-check-input" id="returnOrderAccess"
                       type="checkbox" value="{{returnOrderAccess}}"/>
                <label class="form-check-label" for="returnOrderAccess">
                  Return-Orders
                </label>
              </div>
              <div class="form-check">
                <input [(ngModel)]="canceledOrderAccess" class="form-check-input" id="canceledOrderAccess"
                       type="checkbox" value="{{canceledOrderAccess}}"/>
                <label class="form-check-label" for="canceledOrderAccess">
                  Canceled-Orders
                </label>
              </div>
            </div>

            <div class="col-lg-12">
              <button (click)="accessManagementUpdate()" class="btn btn-rounded btn-block btn-primary halfButton"
                      type="button">
                Update Access
              </button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setAccordionId(4)" [expanded]="noteId === 4" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><i class="sl-icon-cloud-download"></i>&nbsp;&nbsp; Download As Excel</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-lg-12">
              <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                Download As Excel
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

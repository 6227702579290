
  <div class="card">
    <div class="card-body">
      <br>
        <div class="row">
          <div class="col-6 m-t-6 m-b-12">
            <label class="form-control-label">Prefix Cod (default:COD)</label>
            <input [(ngModel)]="prefix" class="form-control" ngModel type="text" value="{{prefix}}"/>
          </div>

        <div class="col-6 m-t-6 m-b-12">
          <label class="form-control-label">Digit Count With COD</label>
          <input [(ngModel)]="digitCount" class="form-control" ngModel type="text" value="{{digitCount}}"/>
        </div>
      </div>

      <br>
      <div class="row">
        <div class="col-6 m-t-6 m-b-12">
          <label class="form-control-label">From (Numbers Only)</label>
          <input [(ngModel)]="fromNumber" class="form-control" ngModel type="text" value="{{fromNumber}}"/>
        </div>

        <div class="col-6 m-t-6 m-b-12">
          <label class="form-control-label">To (Numbers Only)</label>
          <input [(ngModel)]="toNumber" class="form-control" ngModel type="text" value="{{toNumber}}"/>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-lg-12">
        <button (click)="updateCodNumbers()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                type="button">
          Update COD Details
        </button>
      </div>
    </div>
  </div>

<notifier-container></notifier-container>

import { Component, OnInit } from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-items-manager',
  templateUrl: './items-manager.component.html',
  styleUrls: ['./items-manager.component.css']
})
export class ItemsManagerComponentUser implements OnInit {
  allItemsDetails: any[];
  private notifier: NotifierService;
  step: number = -1;
  id: any;
  phNumber: any;
  Title: any;
  SubTitle: any;
  SubTitle_sinhala: any;
  Warranty: any;
  Warranty_sinhala: any;
  Category: any;
  Manufacturer: any;
  Similar_Item: any;
  Priority: any;
  Status: any;
  Web_page: any;
  MainImage: any;
  Image1: any;
  Image2: any;
  Image3: any;
  Image4: any;
  itemSelected = false;
  categoryArray: any = [];
  manufacturerArray: any = [];

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.serviceClass.getItemsAllType().subscribe(
      data => {
        this.allItemsDetails = data;
      }
    );
    this.serviceClass.getCategoryAllType().subscribe(
      data => {
        this.categoryArray = data;
      }
    );
    this.serviceClass.getManufacturerAllType().subscribe(
      data => {
        this.manufacturerArray = data;
      }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  editDevices(data:any) {
    this.step = 0;
    this.itemSelected = true;
    window.scroll(0, 0);
    this.id = data.deviceId ;
    this.Title = data.bot_title ;
    this.phNumber = data.phNumber ;
    this.SubTitle = data.bot_subtitle ;
    this.SubTitle_sinhala = data.bot_subtitle_sinhala ;
    this.Warranty = data.bot_warranty ;
    this.Warranty_sinhala = data.bot_warranty_sinhala ;
    this.Category = data.bot_categoryId ;
    this.Manufacturer = data.bot_manufacturerId ;
    this.Similar_Item = data.bot_similarItems ;
    this.Priority = data.bot_priority ;
    this.Status = data.bot_status ;
    this.Web_page = data.bot_websiteURL ;
    this.MainImage = data.bot_image_url ;
    this.Image1 = data.bot_image_url1 ;
    this.Image2 = data.bot_image_url2 ;
    this.Image3 = data.bot_image_url3 ;
    this.Image4 = data.bot_image_url4 ;
  }

  updateEditDevices(){
    if(this.checkWordValidity(this.Title,'Title')){
    } else if(this.checkWordValidity(this.phNumber,'PH Number')){
    } else if(this.checkWordValidity(this.SubTitle,'SubTitle')){
    } else if(this.checkWordValidity(this.SubTitle_sinhala,'SubTitle sinhala')){
    } else if(this.checkWordValidity(this.Warranty,'Warranty')){
    } else if(this.checkWordValidity(this.Warranty_sinhala,'Warranty sinhala')){
    } else if(this.checkWordValidity(this.Web_page,'Web Page')){
    } else if(this.checkWordValidity(this.Similar_Item,'Similar Items')){
    } else if(this.checkWordValidity(this.MainImage,'Main Image URL')){
    } else if(this.checkWordValidity(this.Image1,'Image 1 URL')){
    } else if(this.checkWordValidity(this.Image2,'Image 2 URL')){
    } else if(this.checkWordValidity(this.Image3,'Image 3 URL')){
    } else if(this.checkWordValidity(this.Image4,'Image 4 URL')){
    } else if(!this.imageChecker(this.MainImage)){
      this.showNotification("error","Please enter valid image url in MainImage");
    } else if(!this.imageChecker(this.Image1)){
      this.showNotification("error","Please enter valid image url in Image1");
    } else if(!this.imageChecker(this.Image2)){
      this.showNotification("error","Please enter valid image url in Image2");
    } else if(!this.imageChecker(this.Image3)){
      this.showNotification("error","Please enter valid image url in Image3");
    } else if(!this.imageChecker(this.Image4)){
      this.showNotification("error","Please enter valid image url in Image4");
    } else {
      this.serviceClass.updateDevice(this.id,this.phNumber, this.Title, this.SubTitle, this.SubTitle_sinhala, this.Warranty, this.Warranty_sinhala, this.Category, this.Manufacturer, this.Similar_Item, this.Priority, this.Status, this.Web_page, this.MainImage, this.Image1, this.Image2, this.Image3, this.Image4).subscribe(
        data => {
          this.serviceClass.getItemsAllType().subscribe(
            data => {
              this.allItemsDetails = data;
              this.itemSelected = false;
              if(data !=="false") {
                this.step = -1;
                this.showNotification("success", "Item updated successfully!");
              } else{
                this.showNotification("error","Failed to update**");
              }
            }
          );
        }
      );
    }
  }

  checkWordValidity = (word, tagName) => {
    if(word === null || word ===undefined){
      this.showNotification("error","All fields are required** ("+tagName+")");
      return true;
    }else if((word.length >= 500 || word.length < 1)){
      this.showNotification("error",tagName+" should be between 1 to 500 characters");
      return true;
    } else{
      return false;
    }
  }

  imageChecker(url) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  setStep(number: number) {
    this.step = number;
  }

  getChar(word: any) {
    if(word === null || word ===undefined){
      return 0;
    }else{
      return word.length;
    }
  }

  getCategoryName = (id) => {
    let title = "-";
    this.categoryArray.forEach((value)=>{
      if(value.id == id){
        title = value.title;
      }
    })
    return title;
  }

  getManufacturerName = (id) => {
    let manufacturer = "-";
    this.manufacturerArray.forEach((value)=>{
      if(value.id == id){
        manufacturer = value.title;
      }
    })
    return manufacturer;
  }
  // ALTER TABLE buyzone_lk.devices MODIFY COLUMN bot_warranty_sinhala VARCHAR(555) CHARACTER SET utf8 COLLATE utf8_general_ci NULL;
}


import {Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ServiceService} from '../../../service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';


// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker

@Component({
  selector: 'app-check-pronto-parcel-accept',
  templateUrl: './check-pronto-parcel-accept.component.html',
  styleUrls: ['./check-pronto-parcel-accept.component.css']
})
export class CheckProntoParcelAcceptComponent implements OnInit {
  // This is for the range date picker
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedDeliveryTypeID: any = 'All';
  selectedDeliveryTypeName: any = 'All';
  totalOrders: any = 0;
  pendingOrdersCount: any = 0;
  dispatchedOrdersCount: any = 0;
  confirmedOrdersCount: any = 0;
  deliveredOrdersCount: any = 0;
  returnedOrdersCount: any = 0;
  warrantyOrdersCount: any = 0;
  canceledOrdersCount: any;
  dataFromDeliveredSheet: any;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.allUsers = data;
      }
    );

    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

  }

  exportAsExcel(): void {
    const currentTimeAndDate = new Date();
    /* table id is passed over here */
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + ' - Dispatch Based On Delivery Type.xlsx');

  }

  formatTime(dateAndTime: any) {
    const newDate = new Date(dateAndTime);
    // tslint:disable-next-line:max-line-length
    return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate() + ' ' + newDate.getHours() + ':' + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    const newDate = new Date(dateAndTime);
    return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    const newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    // tslint:disable-next-line:semicolon
    before(date, this.hoveredDate);

  isInside = date => after(date, this.fromDate) && before(date, this.toDate);

  isFrom = date => equals(date, this.fromDate);

  isTo = date => equals(date, this.toDate);

  selectedDeliveryType(jsonValue: any) {
    this.currentSelectedDeliveryTypeID = jsonValue['id'];
    this.selectedDeliveryTypeName = jsonValue['name'];
  }

  dateFormatter(dateString: any) {
    const newDate = new Date(dateString);
    return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
  }

  timeFormatter(dateString: any) {
    const newDate = new Date(dateString);
    return newDate.getHours() + ':' + newDate.getMinutes() + ':' + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getJsonObjectFromItemDetails(id: any) {
    for (const item of this.phoneDetailsJsonArray) {
      // tslint:disable-next-line:triple-equals
      if (item['deviceId'] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    const deviceArray = this.getJsonObjectFromItemDetails(id);
    // tslint:disable-next-line:triple-equals
    if (deviceArray != undefined) {
      return deviceArray['name'];
    } else {
      return 'No Item';
    }
  }

  updateOrderAndConfirmedCounts() {
    this.pendingOrdersCount = 0;
    this.dispatchedOrdersCount = 0;
    this.confirmedOrdersCount = 0;
    this.deliveredOrdersCount = 0;
    this.canceledOrdersCount = 0;
    this.returnedOrdersCount = 0;
    this.warrantyOrdersCount = 0;

    this.totalOrders = this.rows.length;
    for (const item of this.rows) {
      // tslint:disable-next-line:triple-equals
      if (item['deliveryStatus'] == 'pending') {
        this.pendingOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'confirmedWithCOD' || item['deliveryStatus'] == 'confirmed') {
        this.confirmedOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'dispatched') {
        this.dispatchedOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'delivered') {
        this.deliveredOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'canceled') {
        this.canceledOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'return') {
        this.returnedOrdersCount += 1;
        // tslint:disable-next-line:triple-equals
      } else if (item['deliveryStatus'] == 'warrenty') {
        this.warrantyOrdersCount += 1;
      }
    }
  }

  getLabelType(deliveryStatus: any) {
    // tslint:disable-next-line:triple-equals
    if (deliveryStatus == 'pending') {
      return 'label-primary pending';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'confirmed') {
      return 'label-info confirmed';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'dispatched') {
      return 'label-warning dispatched';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'delivered') {
      return 'label-success delivered';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'return') {
      return 'label-danger return';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'canceled') {
      return 'label-warning canceled';
      // tslint:disable-next-line:triple-equals
    } else if (deliveryStatus == 'warrenty') {
      return 'label-secondary warrenty';
    }
  }

  updateTable() {
    // tslint:disable-next-line:triple-equals
    if (this.dataFromDeliveredSheet == undefined) {
      this.showNotification('error', 'Please enter cod values and check**');
    } else {
      this.totalOrders += 0;
      this.confirmedOrdersCount += 0;
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();

      let tempFromDate = null;
      let tempToDate = null;

      if (this.fromDate != null) {
        const fromDateObj: Date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);

        // fromDateObj.setDate(fromDateObj.getDate() - 1);
        tempFromDate = fromDateObj.getFullYear() + '-' + (fromDateObj.getMonth() + 1) + '-' + fromDateObj.getDate();

        if (this.toDate == null) {
          tempToDate = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
        } else {
          tempToDate = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
        }
      }

      const temporaryOrderNumberArray = [];
      this.rows = [];

      this.serviceClass.getDispatchBasedOnDeliveryType(this.currentSelectedDeliveryTypeID, tempFromDate, tempToDate).subscribe(
        data => {
          for (const item of data) {
            if (temporaryOrderNumberArray.includes(item['orderNumber'])) {
            } else {
              temporaryOrderNumberArray.push(item['orderNumber']);
              const tempValue = {
                COD: item['COD'],
                date: item['dateAndTime'],
                agent: item['agent'],
                price: item['totalAmount'],
                orderNumber: item['orderNumber'],
                addressFirstLine: item['addressFirstLine'],
                addressNumber: item['addressNumber'],
                addressSecondLine: item['addressSecondLine'],
                customerName: item['customerName'],
                deliveryStatus: item['deliveryStatus'],
                city: item['city'],
                fixedLine: item['fixedLine'],
                phoneNumber: item['phoneNumber'],
              };

              this.rows.push(tempValue);
            }
          }
          this.updateOrderAndConfirmedCounts();
        }
      );
    }
  }

  getConfirmedDate(dataArray: any[], orderId: any) {
    for (const item of dataArray) {
      // tslint:disable-next-line:triple-equals
      if (item['orderNumber'] == orderId) {
        // tslint:disable-next-line:triple-equals
        if (item['properName'] == 'confirmed' || item['properName'] == 'confirmedWithCOD') {
          return item['dateAndTime'];
        }
      }
    }
  }

  getDispatchedDate(dataArray: any[], orderId: any) {
    for (const item of dataArray) {
      // tslint:disable-next-line:triple-equals
      if (item['orderNumber'] == orderId) {
        // tslint:disable-next-line:triple-equals
        if (item['properName'] == 'dispatched') {
          return item['dateAndTime'];
        }
      }
    }
  }

  getReturnedDate(dataArray: any[], orderId: any) {
    for (const item of dataArray) {
      // tslint:disable-next-line:triple-equals
      if (item['orderNumber'] == orderId) {
        // tslint:disable-next-line:triple-equals
        if (item['properName'] == 'return') {
          return item['dateAndTime'];
        }
      }
    }
  }

  removeUnnecessarySpaces() {
    this.dataFromDeliveredSheet = this.dataFromDeliveredSheet.split('	').join(',').split('\n').join(';');
  }

  getAcceptanceStatus(codNumber: string, price:any, getColor: Boolean) {
    const nextLineSpacesRemoved = this.dataFromDeliveredSheet.split(';');
    const matches = nextLineSpacesRemoved.filter(s => s.includes(codNumber));
    if (getColor) {
      if (matches.length > 0) {
        const matchPrice = matches.filter(s => s.includes(price));
        if (matchPrice.length > 0 ) {
          return 'green';
        } else {
          return 'red';
        }
      } else {
        return 'red';
      }
    } else {
      if (matches.length > 0) {
        const matchPrice = matches.filter(s => s.includes(price));
        if (matchPrice.length > 0 ) {
          return 'ACCEPTED';
        } else {
          return 'Price Mismatch';
        }
      } else {
        return 'NOT ACCEPTED';
      }
    }
  }
}

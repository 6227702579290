// @ts-ignore
import {Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ServiceService} from "../../../service.service";
// @ts-ignore
import {ActivatedRoute, Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";

declare var require: any;

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css']
})
export class AddOrderComponent implements OnInit {

  step = 0;
  agentEmail: any = 'sasa.uoc@gmail.com';
  previousOrdersArray: any = [];
  phone: any;
  telephone: any;
  backgroundColorOfBlock: any = 'pending';
  name_edit: any = "";
  phone_edit: any = "";
  telephone_edit: any = "";
  nic_edit: any = "";
  deliveryDate_edit: any = "";
  addressNo_edit: any = "";
  firstLine_edit: any = "";
  secondLine_edit: any = "";
  city_edit: any = 'City';
  specialNotes_edit: any = "";
  firstItem_edit: any = '';
  firstItemCount_edit: any = 0;
  firstItemNote_edit: any = "";
  secondItem_edit: any = '';
  secondItemCount_edit: any = 0;
  secondItemNote_edit: any = "";
  thirdItem_edit: any = '';
  thirdItemCount_edit: any = 0;
  thirdItemNote_edit: any = "";
  fourthItem_edit: any = '';
  fourthItemCount_edit: any = 0;
  fourthItemNote_edit: any = "";
  fifthItem_edit: any = '';
  fifthItemCount_edit: any = 0;
  fifthItemNote_edit: any = "";
  discount: any = 0;
  phoneDetailsJsonArray = [];
  keyword = 'name';
  item1PriceSub: any;
  item2PriceSub: any;
  item3PriceSub: any;
  item4PriceSub: any;
  item5PriceSub: any;
  envelope: any;
  firstItem_name_edit: any;
  secondItem_name_edit: any;
  thirdItem_name_edit: any;
  fourthItem_name_edit: any;
  fifthItem_name_edit: any;
  totalPrice: any;
  deliveryTypeArray: any;
  deliveryName: any = '';
  deliveryPrice: any = 0;
  dataToSendToServer: any;
  cityDataListJson = [];
  totalCost: any = 0;
  agent: any = 'Anonymous';
  deliveryId: any;
  private notifier: NotifierService;
  disableAddOrderButton: any;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    const helper = new JwtHelperService();
    this.agentEmail = helper.decodeToken(localStorage.getItem('user_details')).rows[0]["empEmail"];
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

    this.serviceClass.getAllCities().subscribe(
      data => {
        this.cityDataListJson = data;
      }
    );

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.deliveryTypeArray = data;
      }
    );
  }

  addOrder() {
    this.updatePriceTable();
    if (this.name_edit.length == 0) {
      this.showNotification('warning', 'Name is required **');
    } else if (this.phone_edit.length != 10) {
      this.showNotification('warning', 'Please enter valid phone number **');
    } else if (this.telephone_edit.length != 10) {
      this.showNotification('warning', 'Please enter valid telephone number **');
    } else if (this.deliveryDate_edit.length == 0) {
      this.showNotification('warning', 'Delivery Date is required **');
    } else if (this.city_edit == "City") {
      this.showNotification('warning', 'City is required **');
    } else if (this.firstItem_edit.length == 0 && this.secondItem_edit.length == 0 && this.thirdItem_edit.length == 0 && this.fourthItem_edit.length == 0 && this.fifthItem_edit.length == 0) {
      this.showNotification('warning', 'Atleast one item is required to place the order **');
    } else if (this.deliveryName.length == 0) {
      this.showNotification('warning', 'Delivery type is required **');
    } else if (this.firstItem_edit.length != 0 && this.firstItemCount_edit == 0) {
      this.step = 1;
      this.showNotification('warning', 'First item quantity required **');
    } else if (this.secondItem_edit.length != 0 && this.secondItemCount_edit == 0) {
      this.step = 2;
      this.showNotification('warning', 'Second item quantity required **');
    } else if (this.thirdItem_edit.length != 0 && this.thirdItemCount_edit == 0) {
      this.step = 3;
      this.showNotification('warning', 'Third item quantity required **');
    } else if (this.fourthItem_edit.length != 0 && this.fourthItemCount_edit == 0) {
      this.step = 4;
      this.showNotification('warning', 'Fourth item quantity required **');
    } else if (Number(this.totalPrice) < 0) {
      this.step = 5;
      this.showNotification('warning', 'Total amount cannot be negative **');
    } else {
      // this.disableAddOrderButton = true;

      this.dataToSendToServer = {
        device1: this.firstItem_edit,
        numDevice1: this.firstItemCount_edit,
        specialNote1: this.firstItemNote_edit,
        device2: this.secondItem_edit,
        numDevice2: this.secondItemCount_edit,
        specialNote2: this.secondItemNote_edit,
        device3: this.thirdItem_edit,
        numDevice3: this.thirdItemCount_edit,
        specialNote3: this.thirdItemNote_edit,
        device4: this.fourthItem_edit,
        numDevice4: this.fourthItemCount_edit,
        specialNote4: this.fourthItemNote_edit,
        device5: this.fifthItem_edit,
        numDevice5: this.fifthItemCount_edit,
        specialNote5: this.fifthItemNote_edit,
        totalCost: this.totalCost,
        totalAmount: Number(this.totalPrice) - Number(this.discount),
        discount: this.discount,
        totalProfit: (Number(this.totalPrice) - Number(this.totalCost)),
        customerName: this.name_edit,
        phoneNumber: this.phone_edit,
        fixedLine: this.telephone_edit,
        idNumber: this.nic_edit,
        addressNumber: this.addressNo_edit,
        addressFirstLine: this.firstLine_edit,
        addressSecondLine: this.secondLine_edit,
        city: this.city_edit,
        deliveryDate: this.deliveryDate_edit,
        deliveryType: this.deliveryId,
        specialNotes: this.specialNotes_edit,
        agent: this.agentEmail,
        OrderType: "Normal_Order",
        envelope: this.envelope
      };
      if(this.previousOrdersArray.length > 0){
        if(confirm("Did you check the previous orders?")) {
          this.serviceClass.addOrder(this.dataToSendToServer).subscribe(
            data => {
              if (data == true) {
                this.showNotification('success', 'Order Added Successfully!');
                this.resetThePage();
              } else {
                this.showNotification('error', 'Error occurred! Please check and try again!');
              }
            }
          );
        }else{
          this.disableAddOrderButton = false;
        }
      }else{
        this.serviceClass.addOrder(this.dataToSendToServer).subscribe(
          data => {
            if (data == true) {
              this.showNotification('success', 'Order Added Successfully!');
              this.resetThePage();
            } else {
              this.showNotification('error', 'Error occurred! Please check and try again!');
            }
          }
        );
      }

    }
  }

  checkPreviousOrders() {
    if (this.phone_edit != '' && this.telephone_edit != '') {
      this.serviceClass.checkPreviousItemsSearch(this.phone_edit, this.telephone_edit, this.telephone_edit).subscribe(
        data => {
          if (data.length > 0) {
            this.showNotification('warning', 'Previous Orders found for entered details **');
            this.previousOrdersArray = data;
          }
        }
      );
    }
  }

  setSelectBoxValues(dataType: any, item) {
    if (dataType == 'city') {
      this.city_edit = item["name"];
    } else if (dataType == 'item1') {
      this.firstItem_name_edit = item["name"];
      this.firstItem_edit = item["deviceId"];
      this.envelope = item["envelope"];
    } else if (dataType == 'item2') {
      this.secondItem_name_edit = item["name"];
      this.secondItem_edit = item["deviceId"];
    } else if (dataType == 'item3') {
      this.thirdItem_name_edit = item["name"];
      this.thirdItem_edit = item["deviceId"];
    } else if (dataType == 'item4') {
      this.fourthItem_name_edit = item["name"];
      this.fourthItem_edit = item["deviceId"];
    } else if (dataType == 'item5') {
      this.fifthItem_name_edit = item["name"];
      this.fifthItem_edit = item["deviceId"];
    } else if (dataType == 'deliveryType') {
      this.deliveryName = item["name"];
      this.deliveryId = item["id"];
      this.deliveryPrice = item["price"];
      this.updatePriceTable();
    } else {
      this.showNotification('warning', 'Please fix setSelectBoxValues method **');
    }
  }

  updatePriceTable() {
    let temporaryItemPricing: any;
    this.item1PriceSub = 0;
    this.item2PriceSub = 0;
    this.item3PriceSub = 0;
    this.item4PriceSub = 0;
    this.item5PriceSub = 0;
    this.totalCost = 0;

    if (this.deliveryPrice == undefined) {
      this.deliveryPrice = 0;
    }

    if (this.firstItem_edit != '' && this.firstItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.firstItem_edit);
      this.item1PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.firstItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.secondItem_edit != '' && this.secondItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.secondItem_edit);
      this.item2PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.secondItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.thirdItem_edit != '' && this.thirdItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.thirdItem_edit);
      this.item3PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.thirdItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.fourthItem_edit != '' && this.fourthItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.fourthItem_edit);
      this.item4PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.fourthItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    if (this.fifthItem_edit != '' && this.fifthItemCount_edit != 0) {
      temporaryItemPricing = this.getJsonObjectFromItemDetails(this.fifthItem_edit);
      this.item5PriceSub = Number(temporaryItemPricing["soldPrice"]) * this.fifthItemCount_edit;
      this.totalCost += Number(temporaryItemPricing["purchasedPrice"]);
    }

    this.totalPrice = Number(this.item1PriceSub) + Number(this.item2PriceSub) + Number(this.item3PriceSub) + Number(this.item4PriceSub) + Number(this.item5PriceSub) + Number(this.deliveryPrice) - Number(this.discount);
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  shouldShowTheDetails() {
    return this.firstItem_edit != '' || this.secondItem_edit != '' || this.thirdItem_edit != '' || this.fourthItem_edit != '' || this.fifthItem_edit != '';
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  removeAddedItem(itemId: any) {
    if (itemId == '1') {
      this.firstItem_edit = '';
      this.firstItemCount_edit = 0;
      this.firstItemNote_edit = "";
      this.item1PriceSub = 0;
    } else if (itemId == '2') {
      this.secondItem_edit = '';
      this.secondItemCount_edit = 0;
      this.secondItemNote_edit = "";
      this.item2PriceSub = 0;
    } else if (itemId == '3') {
      this.thirdItem_edit = '';
      this.thirdItemCount_edit = 0;
      this.thirdItemNote_edit = "";
      this.item3PriceSub = 0;
    } else if (itemId == '4') {
      this.fourthItem_edit = '';
      this.fourthItemCount_edit = 0;
      this.fourthItemNote_edit = "";
      this.item4PriceSub = 0;
    } else if (itemId == '5') {
      this.fifthItem_edit = '';
      this.fifthItemCount_edit = 0;
      this.fifthItemNote_edit = "";
      this.item5PriceSub = 0;
    }
    this.updatePriceTable();
  }

  refreshButtonValues() {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );

    this.serviceClass.getAllCities().subscribe(
      data => {
        this.cityDataListJson = data;
      }
    );

    this.serviceClass.getAllDeliveryTypes().subscribe(
      data => {
        this.deliveryTypeArray = data;
      }
    );
  }

  resetThePage() {
    window.location.reload();
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "pending") {
      return "label-primary pending";
    } else if (deliveryStatus == "confirmed") {
      return "label-info confirmed";
    } else if (deliveryStatus == "dispatched") {
      return "label-warning dispatched";
    } else if (deliveryStatus == "delivered") {
      return "label-success delivered";
    } else if (deliveryStatus == "return") {
      return "label-danger return";
    } else if (deliveryStatus == "canceled") {
      return "label-warning canceled";
    } else if (deliveryStatus == "warrenty") {
      return "label-secondary warrenty";
    }
  }
}

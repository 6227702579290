import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {JwtHelperService} from "@auth0/angular-jwt";

declare var $: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  tabStatus = 'justified';
  public isCollapsed = false;
  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'mini-sidebar', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  constructor(public router: Router) {
    if (localStorage.getItem("theme") != null) {
      this.options.theme = localStorage.getItem("theme");
    }
    if (localStorage.getItem("sidebarpos") != null) {
      this.options.sidebarpos = localStorage.getItem("sidebarpos");
    }
    if (localStorage.getItem("headerpos") != null) {
      this.options.headerpos = localStorage.getItem("headerpos");
    }
    if (localStorage.getItem("boxed") != null) {
      this.options.boxed = localStorage.getItem("boxed");
    }
    if (localStorage.getItem("dir") != null) {
      this.options.dir = localStorage.getItem("dir");
    }
    if (localStorage.getItem("sidebartype") != null) {
      this.options.sidebartype = localStorage.getItem("sidebartype");
    }
    if (localStorage.getItem("barColor") != null) {
      this.options.logobg = localStorage.getItem("barColor");
      this.options.navbarbg = localStorage.getItem("barColor");
      this.options.sidebarbg = localStorage.getItem("barColor");
    }
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();

    try {
      const helper = new JwtHelperService();
      const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
      if (dataValues["empStatus"] != "active" || dataValues["empPost"] != "admin") {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    } catch (e) {
      this.router.navigate(['/']);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  changeTheme() {
    if (this.options.theme === 'dark') {
      this.options.theme = 'light';
    } else {
      this.options.theme = 'dark';
    }
    localStorage.setItem('theme', this.options.theme);
  }

  changeFixedSidebar() {
    if (this.options.sidebarpos === 'absolute') {
      this.options.sidebarpos = 'fixed';
    } else {
      this.options.sidebarpos = 'absolute';
    }
    localStorage.setItem('sidebarpos', this.options.sidebarpos);
  }

  changeHeader() {
    if (this.options.headerpos === 'absolute') {
      this.options.headerpos = 'fixed';
    } else {
      this.options.headerpos = 'absolute';
    }
    localStorage.setItem('headerpos', this.options.headerpos);
  }

  changeBoxedLayout() {
    if (this.options.boxed === 'full') {
      this.options.boxed = 'boxed';
    } else {
      this.options.boxed = 'full';
    }
    localStorage.setItem('boxed', this.options.boxed);
  }

  changeRtl() {
    if (this.options.dir === 'rtl') {
      this.options.dir = 'ltr';
    } else {
      this.options.dir = 'rtl';
    }
    localStorage.setItem('dir', this.options.dir);
  }

  changeSideBarType(type) {
    this.options.sidebartype = type;
    localStorage.setItem('sidebartype', this.options.sidebartype);
  }

  changeNavBarColors(color) {
    this.options.logobg = color;
    this.options.navbarbg = color;
    this.options.sidebarbg = color;
    localStorage.setItem('barColor', color);
  }

  updateUserActivities() {
    console.log("sasa");
  }
}

<div mat-raised-button></div>
<div class="row">
  <!-- column -->
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">

        <form class="w-50 m-b-30">
          <div class="btn-group mr-3">

            <input (change)='filterBySearch($event)'
                   class="form-control btn-group text-field-controller outline-primary" placeholder='Search...'
                   type='text'/>

            <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown role="group">
              <button class="btn btn-outline-primary" ngbDropdownToggle>{{deliveryStatusBtn}}</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button (click)="filterWithDeliveryStatus('Default')" class="dropdown-item pointer">Default</button>
                <button (click)="filterWithDeliveryStatus('Pending')" class="dropdown-item pointer">Pending</button>
                <button (click)="filterWithDeliveryStatus('Confirmed')" class="dropdown-item pointer">Confirmed</button>
                <button (click)="filterWithDeliveryStatus('Dispatched')" class="dropdown-item pointer">Dispatched
                </button>
                <button (click)="filterWithDeliveryStatus('Delivered')" class="dropdown-item pointer">Delivered</button>
                <button (click)="filterWithDeliveryStatus('Canceled')" class="dropdown-item pointer">Canceled</button>
                <button (click)="filterWithDeliveryStatus('Return')" class="dropdown-item pointer">Return</button>
                <button (click)="filterWithDeliveryStatus('Warrenty')" class="dropdown-item pointer">Warranty</button>
              </div>
            </div>

            <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown role="group">
              <button class="btn btn-outline-primary" ngbDropdownToggle>{{numberOfRowsBtn}}</button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button (click)="updateDisplayingNumberOfRows(10)" class="dropdown-item pointer">10</button>
                <button (click)="updateDisplayingNumberOfRows(20)" class="dropdown-item pointer">20</button>
                <button (click)="updateDisplayingNumberOfRows(100)" class="dropdown-item pointer">100</button>
                <button (click)="updateDisplayingNumberOfRows(200)" class="dropdown-item pointer">200</button>
                <button (click)="updateDisplayingNumberOfRows(500)" class="dropdown-item pointer">500</button>
                <button (click)="updateDisplayingNumberOfRows(1000)" class="dropdown-item pointer">1000</button>
                <button (click)="updateDisplayingNumberOfRows(10000)" class="dropdown-item pointer">10000</button>
                <button (click)="updateDisplayingNumberOfRows(100000)" class="dropdown-item pointer">100000</button>
              </div>
            </div>

            <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown role="group">
              <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary"
                      type="button">
                Download As Excel
              </button>
            </div>

          </div>

        </form>

        <div class="col-12">
          <div class="table-responsive">
            <ngx-spinner></ngx-spinner>
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Order Num</th>
                <th scope="col">COD</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Fixed Line</th>
                <th scope="col">Order</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Note</th>
                <th scope="col">Delivery Type</th>
                <th scope="col">Delivery Status</th>
                <th scope="col">Agent</th>
              </tr>
              </thead>

              <tbody>
              <tr (click)="routeToSingleOrderPage(indexElement.orderNumber)" *ngFor="let indexElement of rows">
                <td>{{dateFormatter(indexElement.date)}}</td>
                <td>{{indexElement.orderNumber}}</td>
                <td>{{indexElement.COD}}</td>
                <td>{{indexElement.customerName}}</td>
                <td>{{indexElement.phoneNumber}}</td>
                <td>{{indexElement.fixedLine}}</td>
                <td >
                  <i data-placement="top" data-html="true" data-toggle="tooltip" style="cursor: pointer;"
                     title=" {{getToolTipForOrderDetails(getItemName(indexElement.device1),indexElement.numDevice1,getItemName(indexElement.device2),indexElement.numDevice2,getItemName(indexElement.device3),indexElement.numDevice3,getItemName(indexElement.device4),indexElement.numDevice4,getItemName(indexElement.device5),indexElement.numDevice5)}}" class="fas fa-eye"></i></td>
                <td>{{indexElement.price}}</td>
                <td>{{dateFormatter(indexElement.deliveryDate)}}</td>
                <td data-placement="top" data-toggle="tooltip" style="cursor: pointer;"
                    title="{{getNoteToolTip(indexElement.note1,indexElement.note2,indexElement.note3)}}">
                  <i class="fas fa-eye"></i></td>
                <td>{{getDeliveryTypeName(indexElement.deliveryType)}}</td>
                <td>
                  <span
                    class="label {{getLabelType(indexElement.deliveryStatus)}}">{{indexElement.deliveryStatus}}</span>
                </td>
                <td>{{getUserNameFromEmail(indexElement.agentName)}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ServiceService} from "../../service.service";

@Component({
  selector: 'app-address-sticker-with-date',
  templateUrl: './address-sticker-with-date.component.html',
  styleUrls: ['./address-sticker-with-date.component.css']
})
export class AddressStickerWithDateComponent implements OnInit {
  rows: any[];
  private date: any;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.date = params['date'];
    });
  }

  ngOnInit() {
    this.serviceClass.getProntoUploadSheetDetails('All', this.date, null).subscribe(
      data => {
        this.rows = [...data];
      }
    );
  }
}

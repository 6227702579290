<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" id="bootstrap-css" rel="stylesheet"/>
<div id="invoice">
  <div class="invoice overflow-auto">
    <div style="min-width: 600px;">
      <br/><br/><br/><br/><br/><br/><br/><br/>
      <header>
<!--        <div class="row">-->
<!--          <div class="col">-->
<!--            <img class="invoiceLogo" data-holder-rendered="true" src="assets/images/logo/wide-logo-black.png"/>-->
<!--          </div>-->
<!--          <div class="col company-details">-->
<!--            <h2 class="name biggerFont">-->
<!--              Unikwear.lk-->
<!--            </h2>-->
<!--            <div class="biggerFont">-->
<!--              245,<br/>-->
<!--              Maya Mawatha,<br/>-->
<!--              Kiribathgoda,<br/>-->
<!--              11600-->
<!--            </div>-->
<!--            <div class="biggerFont">071 519 0899</div>-->
<!--            <div class="biggerFont">info@unikwear.lk</div>-->
<!--          </div>-->
<!--        </div>-->
      </header>
      <main>
        <div class="row contacts">
          <div class="col invoice-to">
            <div class="text-gray-light">Delivery to:</div>
            <div class="to biggerFont">{{clientName}} (NIC : {{nicNumber}})</div>
            <div class="address biggerFont">{{addressNumber}},</div>
            <div class="address biggerFont">{{firstLine}},</div>
            <div class="address biggerFont">{{secondLine}},</div>
            <div class="address biggerFont">{{city}}.</div>
            <div class="email biggerFont">{{phoneNumber}} / {{telephone}}</div>
          </div>
          <div class="col invoice-details">
            <h1 class="invoice-id">{{codNumber}}</h1>
            <div class="date biggerFont">Date of Invoice: {{todayDate}}</div>
          </div>
        </div>
        <table border="0" cellpadding="0" cellspacing="0">
          <thead>
          <tr>
            <th>#</th>
            <th class="text-left biggerFont">DESCRIPTION</th>
            <th class="text-right biggerFont">ITEM PRICE</th>
            <th class="text-right biggerFont">QTY</th>
            <th class="text-right biggerFont">TOTAL</th>
          </tr>
          </thead>
            <tbody>
              <tr *ngIf="item1 != '0'">
                <td class="no biggerFont">01</td>
                <td class="text-left biggerFont">{{item1}}</td>
                <td class="unit biggerFont">{{unitPrice1}}.00 Lkr</td>
                <td class="qty biggerFont">{{qty1}}</td>
                <td class="total biggerFont">{{unitTotal1}}.00 Lkr</td>
              </tr>

              <tr *ngIf="item2 != '0'">
                <td class="no biggerFont">02</td>
                <td class="text-left biggerFont">{{item2}}</td>
                <td class="unit biggerFont">{{unitPrice2}}.00 Lkr</td>
                <td class="qty biggerFont">{{qty2}}</td>
                <td class="total biggerFont">{{unitTotal2}}.00 Lkr</td>
              </tr>

              <tr *ngIf="item3 != '0'">
                <td class="no biggerFont">03</td>
                <td class="text-left biggerFont">{{item3}}</td>
                <td class="unit biggerFont">{{unitPrice3}}.00 Lkr</td>
                <td class="qty biggerFont">{{qty3}}</td>
                <td class="total biggerFont">{{unitTotal3}}.00 Lkr</td>
              </tr>

              <tr *ngIf="item4 != '0'">
                <td class="no biggerFont">04</td>
                <td class="text-left biggerFont">{{item4}}</td>
                <td class="unit biggerFont">{{unitPrice4}}.00 Lkr</td>
                <td class="qty biggerFont">{{qty4}}</td>
                <td class="total biggerFont">{{unitTotal4}}.00 Lkr</td>
              </tr>

              <tr *ngIf="item5 != '0'">
                <td class="no biggerFont">05</td>
                <td class="text-left biggerFont">{{item5}}</td>
                <td class="unit biggerFont">{{unitPrice5}}.00 Lkr</td>
                <td class="qty biggerFont">{{qty5}}</td>
                <td class="total biggerFont">{{unitTotal5}}.00 Lkr</td>
              </tr>
            </tbody>
          <tfoot>
          <tr>
            <td colspan="2"></td>
            <td colspan="2" class="biggerFont">Delivery cost</td>
            <td class="biggerFont">{{getDeliveryPrice()?.price}}.00 LKR</td>
          </tr>
          <tr *ngIf="discount > 0">
            <td colspan="2"></td>
            <td colspan="2" class="biggerFont">Discount</td>
            <td class="biggerFont">- {{discount}}.00 LKR</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td colspan="2"><h1>GRAND TOTAL</h1></td>
            <td>{{total + getDeliveryPrice()?.price}}.00 LKR</td>
          </tr>
          </tfoot>
        </table>
        <h2 style="text-align: center">{{specialNote1}}</h2>
        <h1>Thank you!</h1>
<!--        <div class="notices">-->
<!--          <div>Tracking Code:</div>-->
<!--          <div class="notice"><ngx-barcode [bc-height]="80"-->
<!--                                           [bc-margin-bottom]="0"-->
<!--                                           [bc-margin-top]="0"-->
<!--                                           [bc-value]="codNumber"-->
<!--                                           [bc-display-value]="true"-->
<!--                                           [bc-width]="1.8"></ngx-barcode></div>-->
<!--          <div class="notice1 biggerFont">*EXCHANGE IS POSSIBLE WITHING 7 DAYS OF PURCHASE WITH TAG, ITEMS NEED TO BE IN ORIGINAL CONDITION & ACCOMPLISHED WITH THE INVOICE.</div>-->
<!--        </div>-->
      </main>
    </div>
    <div></div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <mat-accordion>
          <mat-expansion-panel disabled (opened)="setStep(0)" [expanded]="step === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Item Details Update
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">PH Number</label>
                <input [(ngModel)]="phNumber" class="form-control" ngModel type="text" value="{{phNumber}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Title <small>({{getChar(Title)}}/500)</small></label>
                <input [(ngModel)]="Title" class="form-control" ngModel type="text" value="{{Title}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Subtitle <small>({{getChar(SubTitle)}}/500)</small></label>
                <input [(ngModel)]="SubTitle" class="form-control" ngModel type="text" value="{{SubTitle}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Subtitle Sinhala <small>({{getChar(SubTitle_sinhala)}}/500)</small></label>
                <input [(ngModel)]="SubTitle_sinhala" class="form-control" ngModel type="text" value="{{SubTitle_sinhala}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Warranty <small>({{getChar(Warranty)}}/500)</small></label>
                <input [(ngModel)]="Warranty" class="form-control" ngModel type="text" value="{{Warranty}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Warranty Sinhala <small>({{getChar(Warranty_sinhala)}}/500)</small></label>
                <input [(ngModel)]="Warranty_sinhala" class="form-control" ngModel type="text" value="{{Warranty_sinhala}}"/>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Category</label>
                <select [(ngModel)]="Category" class="form-control" ngModel value="{{Category}}">
                  <option *ngFor="let category of categoryArray" value="{{category.id}}">{{category.title}}</option>
                </select>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Manufacturer</label>
                <select [(ngModel)]="Manufacturer" class="form-control" ngModel value="{{Manufacturer}}">
                  <option *ngFor="let manufacturer of manufacturerArray" value="{{manufacturer.id}}">{{manufacturer.title}}</option>
                </select>
              </div>

              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Similar Item</label>
                <input [(ngModel)]="Similar_Item" class="form-control" ngModel type="text" value="{{Similar_Item}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Priority</label>
                <input [(ngModel)]="Priority" class="form-control" ngModel type="text" value="{{Priority}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Status</label>
                <select [(ngModel)]="Status" class="form-control" ngModel value="{{Status}}">
                  <option value="active">Active</option>
                  <option value="de-active">De-active</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Webpage</label>
                <input [(ngModel)]="Web_page" class="form-control" ngModel type="text" value="{{Web_page}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Main Image</label>
                <input [(ngModel)]="MainImage" class="form-control" ngModel type="text" value="{{MainImage}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Image 1</label>
                <input [(ngModel)]="Image1" class="form-control" ngModel type="text" value="{{Image1}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Image 2</label>
                <input [(ngModel)]="Image2" class="form-control" ngModel type="text" value="{{Image2}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Image 3</label>
                <input [(ngModel)]="Image3" class="form-control" ngModel type="text" value="{{Image3}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10">
                <label class="form-control-label">Image 4</label>
                <input [(ngModel)]="Image4" class="form-control" ngModel type="text" value="{{Image4}}"/>
              </div>
              <div class="col-lg-3 col-md-5 m-t-10 m-b-10" *ngIf="itemSelected">
                <label class="form-control-label"> &nbsp;</label>
                <button class="btn btn-rounded btn-block btn-outline-success halfButton" type="button" (click)="updateEditDevices()">
                  Update
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">PhNumber</th>
                <th scope="col">Title</th>
                <th scope="col">SubTitle</th>
                <th scope="col">In Sinhala</th>
                <th scope="col">Warranty</th>
                <th scope="col">In Sinhala</th>
                <th scope="col">Category</th>
                <th scope="col">Manufacturer</th>
                <th scope="col">Similar Items</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                <th scope="col">Web Page </th>
                <th scope="col">Main Image</th>
                <th scope="col">Image 1</th>
                <th scope="col">Image 2</th>
                <th scope="col">Image 3</th>
                <th scope="col">Image 4</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of allItemsDetails" class="pointer" (click)="editDevices(indexElement)">
                <td>{{indexElement.deviceId}}</td>
                <td>{{indexElement.phNumber}}</td>
                <td>{{indexElement.bot_title}}</td>
                <td>{{indexElement.bot_subtitle}}</td>
                <td>{{indexElement.bot_subtitle_sinhala}}</td>
                <td>{{indexElement.bot_warranty}}</td>
                <td>{{indexElement.bot_warranty_sinhala}}</td>
                <td>{{getCategoryName(indexElement.bot_categoryId)}}</td>
                <td>{{getManufacturerName(indexElement.bot_manufacturerId)}}</td>
                <td>{{indexElement.bot_similarItems}}</td>
                <td>{{indexElement.bot_priority}}</td>
                <td>{{indexElement.bot_status}}</td>
                <td><a href="{{indexElement.bot_websiteURL}}" target="_blank"><button class="btn btn-rounded btn-block btn-outline-primary"><i class="fas fa-external-link-alt"></i></button></a></td>
                <td><img class="categoryImage" src="{{indexElement.bot_image_url}}"></td>
                <td><img class="categoryImage" src="{{indexElement.bot_image_url1}}"></td>
                <td><img class="categoryImage" src="{{indexElement.bot_image_url2}}"></td>
                <td><img class="categoryImage" src="{{indexElement.bot_image_url3}}"></td>
                <td><img class="categoryImage" src="{{indexElement.bot_image_url4}}"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<notifier-container></notifier-container>

import {Component, OnDestroy} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import {timer} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  private sub: any;

  constructor(private slimLoader: SlimLoadingBarService, private router: Router) {
    // Listen the navigation events to start or complete the slim bar loading
    this.sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.slimLoader.start();
      } else if (event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.slimLoader.complete();
      }
    }, (error: any) => {
      this.slimLoader.complete();
    });
    const timerIncrement = timer(1, 10000);
    timerIncrement.subscribe(x => {
      this.authenticateUser()
    });
  }

  authenticateUser() {
    const windowHash = window.location.hash;
    const helper = new JwtHelperService();
    if (localStorage.getItem('user_details') !== null) {
      if (!helper.isTokenExpired(localStorage.getItem('user_details'))) {
        if (windowHash !== "#/login") {
          const urlArray = windowHash.split("/");
          if (urlArray[1] == "admin") {
            try {
              const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
              if (dataValues["empStatus"] != "active" || dataValues["empPost"] != "admin") {
                localStorage.clear();
                this.router.navigate(['/']);
              }
            } catch (e) {
              localStorage.clear();
              this.router.navigate(['/']);
            }
          } else if (urlArray[1] == "user") {
            try {
              const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
              if (dataValues["empStatus"] != "active" || dataValues["empPost"] != "standard") {
                localStorage.clear();
                this.router.navigate(['/']);
              }
            } catch (e) {
              localStorage.clear();
              this.router.navigate(['/']);
            }

          } else if (urlArray[1] == "dispatch" || urlArray[1] == "invoice" || urlArray[1] == "multiUser" || urlArray[1] == "address") {
            try {
              const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
              if (dataValues["empStatus"] != "active") {
                localStorage.clear();
                this.router.navigate(['/']);
              }
            } catch (e) {
              localStorage.clear();
              this.router.navigate(['/']);
            }
          } else {
            console.log(urlArray[1]);
            localStorage.clear();
            this.router.navigate(['/']);
          }
        }
      } else {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    }
  }

  ngOnDestroy(): any {
    this.sub.unsubscribe();
  }
}

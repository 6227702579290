<div class="book">
  <div class="page">

    <div class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <ngb-datepicker #dp [(ngModel)]="selectedDate" [displayMonths]="1" ngModel>
        </ngb-datepicker>
      </div>
      <div class="col-lg-4"></div>
    </div>
    <br>
    <br>

    <div class="row">
      <div class="col-lg-12">
        <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                type="button">
          Get Address Stickers
        </button>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

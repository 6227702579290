import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ChartsModule} from 'ng2-charts';
import {ChartistModule} from 'ng-chartist';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CalendarModule} from 'angular-calendar';

import {DashboardRoutes} from './dashboard.routing';
import {NotifierModule} from 'angular-notifier';
import {UpdateOrderComponent} from './OrderManagement/update-order/update-order.component';
import {QuillModule} from 'ngx-quill';
import {AddOrderComponent} from './OrderManagement/add-order/add-order.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// @ts-ignore
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// @ts-ignore
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
// @ts-ignore
import {MatBadgeModule} from '@angular/material/badge';
// @ts-ignore
import {MatButtonModule} from '@angular/material/button';
// @ts-ignore
import {MatButtonToggleModule} from '@angular/material/button-toggle';
// @ts-ignore
import {MatCardModule} from '@angular/material/card';
// @ts-ignore
import {MatCheckboxModule} from '@angular/material/checkbox';
// @ts-ignore
import {MatChipsModule} from '@angular/material/chips';
// @ts-ignore
import {MatStepperModule} from '@angular/material/stepper';
// @ts-ignore
import {MatDatepickerModule} from '@angular/material/datepicker';
// @ts-ignore
import {MatDialogModule} from '@angular/material/dialog';
// @ts-ignore
import {MatDividerModule} from '@angular/material/divider';
// @ts-ignore
import {MatExpansionModule} from '@angular/material/expansion';
// @ts-ignore
import {MatGridListModule} from '@angular/material/grid-list';
// @ts-ignore
import {MatIconModule} from '@angular/material/icon';
// @ts-ignore
import {MatInputModule} from '@angular/material/input';
// @ts-ignore
import {MatListModule} from '@angular/material/list';
// @ts-ignore
import {MatMenuModule} from '@angular/material/menu';
// @ts-ignore
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
// @ts-ignore
import {MatPaginatorModule} from '@angular/material/paginator';
// @ts-ignore
import {MatProgressBarModule} from '@angular/material/progress-bar';
// @ts-ignore
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// @ts-ignore
import {MatRadioModule} from '@angular/material/radio';
// @ts-ignore
import {MatSelectModule} from '@angular/material/select';
// @ts-ignore
import {MatSidenavModule} from '@angular/material/sidenav';
// @ts-ignore
import {MatSliderModule} from '@angular/material/slider';
// @ts-ignore
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// @ts-ignore
import {MatSnackBarModule} from '@angular/material/snack-bar';
// @ts-ignore
import {MatSortModule} from '@angular/material/sort';
// @ts-ignore
import {MatTableModule} from '@angular/material/table';
// @ts-ignore
import {MatTabsModule} from '@angular/material/tabs';
// @ts-ignore
import {MatToolbarModule} from '@angular/material/toolbar';
// @ts-ignore
import {MatTooltipModule} from '@angular/material/tooltip';
// @ts-ignore
import {MatTreeModule} from '@angular/material/tree';
// @ts-ignore
import {MatFormFieldModule} from '@angular/material/form-field';
import {Order_creationComponent} from './Reports/order_creation/order_creation.component';
import {OrderConfirmationComponent} from './Reports/order-confirmation/order-confirmation.component';
import {OrderPendingComponent} from './Reports/order-pending/order-pending.component';
import {OrderDeliveredComponent} from './Reports/order-delivered/order-delivered.component';
import {OrderReturnedComponent} from './Reports/order-returned/order-returned.component';
import {OrderCanceledComponent} from './Reports/order-canceled/order-canceled.component';
import {DispatchBaseonDeliveryTypeComponent} from './Reports/dispatch-baseon-delivery-type/dispatch-baseon-delivery-type.component';
import {DeliveryBasedonDateComponent} from './Reports/delivery-basedon-date/delivery-basedon-date.component';
import {OrderAccuracyComponent} from './Reports/order-accuracy/order-accuracy.component';
import {ItemWiseComponent} from './Reports/item-wise/item-wise.component';
import {UploadingSheetComponent} from './Reports/uploading-sheet/uploading-sheet.component';
import {OrderDetailsStickerPasteComponent} from './Reports/order-details-sticker-paste/order-details-sticker-paste.component';
import {AddressStickerPrintComponent} from './Reports/address-sticker-print/address-sticker-print.component';
import {DispatchDateCountComponent} from './Reports/dispatch-to-delivered-date-count/dispatch-date-count.component';
import {DispatchToReturnDateCountComponent} from './Reports/dispatch-to-return-date-count/dispatch-to-return-date-count.component';
import {ReturnReportForReturnedDateComponent} from './Reports/return-report-for-returned-date/return-report-for-returned-date.component';
import {CashAcceptedCheckingComponent} from './cash-accepted-checking/cash-accepted-checking.component';
import {CheckProntoParcelAcceptComponent} from './Reports/check-pronto-parcel-accept/check-pronto-parcel-accept.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {ItemManagementComponent} from './Management/item-management/item-management.component';
import {EmployeeManagementComponentAdmin} from './Management/employee-management/employee-management.component';
import {CheckReturnOrdersComponent} from './Reports/check-return-orders/check-return-orders.component';
import {MainOrdersComponent} from './OrderManagement/main-orders/main-orders.component';
import {NavigationComponent} from './shared/header-navigation/navigation.component';
import {DispatchOrderComponent} from './OrderManagement/dispatch-order/dispatch-order.component';
import {SpeechRecognitionModule} from '@kamiazya/ngx-speech-recognition';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NbCardModule} from '@nebular/theme';
import {NgxBarcodeModule} from 'ngx-barcode';
import { AddressPrintA6Component } from './address-print-a6/address-print-a6.component';
import { ReturnOrderComponent } from './OrderManagement/return-order/return-order.component';
import { BulkOrderDeliveredComponent } from './OrderManagement/bulk-order-delivered/bulk-order-delivered.component';
import { WarrantyManagerComponent } from './warranty-manager/warranty-manager.component';
import { ProfileComponent } from './profile/profile.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { BackupDownloadComponent } from './backup-download/backup-download.component';
import { CategoryManagerComponent } from './ChatBot/category-manager/category-manager.component';
import { ManufacturerManagerComponent } from './ChatBot/manufacturer-manager/manufacturer-manager.component';
import { PromotionManagerComponent } from './ChatBot/promotion-manager/promotion-manager.component';
import { ItemsManagerComponent } from './ChatBot/items-manager/items-manager.component';
import { DisableChatComponent } from './ChatBot/disable-chat/disable-chat.component';
import {CurrentDispatchedListComponent} from './Reports/current-dispatched-list/current-dispatched-list.component';
import { DispatchTrackerComponent } from './OrderManagement/dispatch-tracker/dispatch-tracker.component';
import {DatepickerModule} from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgbModule,
    ChartsModule,
    ChartistModule,
    RouterModule.forChild(DashboardRoutes),
    PerfectScrollbarModule,
    CalendarModule.forRoot(),
    NgxChartsModule,
    NgxDatatableModule,
    NotifierModule,
    InfiniteScrollModule,
    MatBottomSheetModule,
    MatListModule,
    QuillModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    MatInputModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatMenuModule,
    MatNativeDateModule,
    NgxBarcodeModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    SpeechRecognitionModule.withConfig({
      lang: 'en-US',
      interimResults: true,
      maxAlternatives: 1,
    }),
    NgxSpinnerModule,
    NbCardModule,
    DatepickerModule,
  ],
  exports: [
    NavigationComponent
  ],
  declarations: [
    UpdateOrderComponent,
    MainOrdersComponent,
    AddOrderComponent,
    Order_creationComponent,
    OrderConfirmationComponent,
    OrderPendingComponent,
    OrderDeliveredComponent,
    OrderReturnedComponent,
    OrderCanceledComponent,
    DispatchBaseonDeliveryTypeComponent,
    DeliveryBasedonDateComponent,
    OrderAccuracyComponent,
    ItemWiseComponent,
    UploadingSheetComponent,
    OrderDetailsStickerPasteComponent,
    AddressStickerPrintComponent,
    DispatchDateCountComponent,
    DispatchToReturnDateCountComponent,
    ReturnReportForReturnedDateComponent,
    CashAcceptedCheckingComponent,
    CheckProntoParcelAcceptComponent,
    InvoiceComponent,
    ItemManagementComponent,
    EmployeeManagementComponentAdmin,
    CheckReturnOrdersComponent,
    NavigationComponent,
    DispatchOrderComponent,
    AddressPrintA6Component,
    ReturnOrderComponent,
    BulkOrderDeliveredComponent,
    WarrantyManagerComponent,
    ProfileComponent,
    BackupDownloadComponent,
    CategoryManagerComponent,
    ManufacturerManagerComponent,
    PromotionManagerComponent,
    ItemsManagerComponent,
    DisableChatComponent,
    CurrentDispatchedListComponent,
    DispatchTrackerComponent,
  ]
})

export class DashboardModule {
}

<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
  <div class="row">
    <div class="col-5 align-self-center">
      <h4 class="page-title">{{pageInfo?.title}}</h4>
      <div class="d-flex align-items-center">

      </div>
    </div>
    <div class="col-7 align-self-center">
      <div class="d-flex no-block justify-content-end align-items-center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <ng-template [ngForOf]="pageInfo?.urls" let-last="last" let-url ngFor>
              <li *ngIf="!last" [routerLink]="url.url" class="breadcrumb-item">
                <a href='javascript:void(0)'>{{url.title}}</a>
              </li>
              <li *ngIf="last" class="breadcrumb-item active">{{url.title}}</li>
            </ng-template>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->

<div class="row">
  <div class="col-lg-9">

    <div class="book">
      <div class="page">

        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <h3><b>Enter COD And Prices,</b></h3>
            <textarea (change)="removeUnnecessarySpaces()" [(ngModel)]="dataFromDeliveredSheet"
                      class="textArea"></textarea>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover table-bordered " id="excel-table" style="text-align: center;">
              <thead>
              <tr>
                <th scope="col">COD</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Mobile</th>
                <th scope="col">Fixed Line</th>
                <th scope="col">Acceptance Status</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let indexElement of rows" class="{{getAcceptanceStatus(indexElement.COD, indexElement.price , true)}} normalRow">
                <td>{{indexElement.COD}}</td>
                <td>{{indexElement.customerName}}</td>
                <td>{{indexElement.addressNumber}},{{indexElement.addressFirstLine}},{{indexElement.addressSecondLine}},{{indexElement.city}}</td>
                <td>{{indexElement.phoneNumber}}</td>
                <td>{{indexElement.fixedLine}}</td>
                <td>{{getAcceptanceStatus(indexElement.COD, indexElement.price ,false)}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card">
      <div class="card-body mainBlockColor warrenty">
        <br/>
        <h1 class="orderDetails">Total Count : {{totalOrders}}</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <label class="form-control-label">Delivery Type</label>
          <ng-autocomplete
            (selected)='selectedDeliveryType($event)'
            [data]="allUsers"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [placeHolder]="currentSelectedDeliveryTypeID"
            [searchKeyword]="keyword">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <label class="form-control-label">Date Or Date-Range</label>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <ngb-datepicker #dp (ngModelChange)="onDateChange($event)" [dayTemplate]="t" [displayMonths]="1" ngModel>
            </ngb-datepicker>

            <ng-template #t let-date="date" let-focused="focused">
                <span (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.focused]="focused"
                      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                      class="custom-day">
                    {{ date.day }}
                </span>
            </ng-template>
          </div>
          <div class="col-lg-2"></div>
        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-lg-12">
            <button (click)="updateTable()" class="btn btn-rounded btn-block btn-outline-primary halfButton"
                    type="button">
              Update Table
            </button>
            <br>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-primary halfButton"
                    type="button">
              Download As Excel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>

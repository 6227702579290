import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import Speech from 'speak-tts'
import {JwtHelperService} from "@auth0/angular-jwt";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-dispatch-order',
  templateUrl: './dispatch-order.component.html',
  styleUrls: ['./dispatch-order.component.css']
})
export class DispatchOrderComponent implements OnInit {
  phoneDetailsJsonArray: any[];

  codNumber: any;
  agentEmail: any;
  item1: any;
  qty1: any;
  specialNote1: any;
  item2: any;
  qty2: any;
  specialNote2: any;
  item3: any;
  qty3: any;
  specialNote3: any;
  item4: any;
  qty4: any;
  specialNote4: any;
  item5: any;
  qty5: any;
  specialNote5: any;
  totalAmount: any;
  agent: any;
  addressFirstLine: any;
  addressNumber: any;
  addressSecondLine: any;
  city: any;
  deliveryDate: any;
  deliveryStatus: any;
  deliveryType: any;
  specialNotes: any;
  customerName: any;
  phoneNumber: any;
  nic: any;
  telephoneNumber: any;
  orderNumber: any;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    const helper = new JwtHelperService();
    this.agentEmail = helper.decodeToken(localStorage.getItem('user_details')).rows[0]["empEmail"];
  }

  ngOnInit(): void {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }


  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  speechConfig(speechContent: any) {
    const speech = new Speech() // will throw an exception if not browser supported
    if (speech.hasBrowserSupport()) { // returns a boolean
      const speech = new Speech()
      speech.init().then((data) => {
        speech.init({
          'volume': 1,
          'lang': 'en-GB',
          'rate': 10,
          'pitch': 1,
          'voice': 'Google UK English Male',
          'splitSentences': true,
          'listeners': {
            'onvoiceschanged': (voices) => {
            }
          }
        })

        speech.speak(<SpeechSynthesisUtterance>{
          text: speechContent,
        }).then(() => {
        }).catch(e => {
        })
      }).catch(e => {
        console.error("An error occurred while initializing : ", e)
      })
    }
  }

  updateCodId() {
    this.serviceClass.dispatchOrderDetails(this.codNumber, this.agentEmail).subscribe(
      data => {
        // if(data['prontoUpload']) {
        //   if (data['prontoUpload'].toString().includes("fail")) {
        //     alert("Pronto System is not updated.")
        //   }
        // }
        if(data["sqlDetails"]){
          data = data["sqlDetails"];
        }
        // @ts-ignore
        if (data["confirmationType"] != false) {
          // @ts-ignore
          // console.log(data.length);
          // this.serviceClass.prontoUpload(data[0]).subscribe(
          //   data => {
          //     if(data["status"] === "success") {
          //       alert("Done");
          //       console.log(data);
          //     }
          //   }
          // );
          // @ts-ignore
          if (data.length !== 0) {

            this.item1 = this.getItemName(data[0].device1);
            this.qty1 = data[0].numDevice1;
            this.specialNote1 = data[0].specialNote1;

            this.item2 = this.getItemName(data[0].device2);
            this.qty2 = data[0].numDevice2;
            this.specialNote2 = data[0].specialNote2;

            this.item3 = this.getItemName(data[0].device3);
            this.qty3 = data[0].numDevice3;
            this.specialNote3 = data[0].specialNote3;

            this.item4 = this.getItemName(data[0].device4);
            this.qty4 = data[0].numDevice4;
            this.specialNote4 = data[0].specialNote4;

            this.item5 = this.getItemName(data[0].device5);
            this.qty5 = data[0].numDevice5;
            this.specialNote5 = data[0].specialNote5;

            this.customerName = data[0].customerName;
            this.phoneNumber = data[0].phoneNumber;
            this.telephoneNumber = data[0].fixedLine;

            this.totalAmount = data[0].totalAmount;
            this.agent = data[0].agent;
            this.addressFirstLine = data[0].addressFirstLine;
            this.addressNumber = data[0].addressNumber;
            this.addressSecondLine = data[0].addressSecondLine;
            this.city = data[0].city;
            this.deliveryDate = data[0].deliveryDate;
            this.deliveryStatus = data[0].deliveryStatus;
            this.deliveryType = data[0].deliveryType;
            this.specialNotes = data[0].specialNotes;
            this.orderNumber = data[0].orderNumber;
            this.nic = data[0].NIC;
            // @ts-ignore
            document.getElementById("codNumber").select();

            this.serviceClass.sendSms(this.phoneNumber, "Order Dispatched.\\nOrder Number: "+this.nic+".\\nTracking Number: "+this.codNumber+".\\nTotal Price: "+this.totalAmount+"\\nCheck out our new collection at www.unikwear.lk").subscribe(
                data => {
                  this.showNotification('success', 'Message Sent');
                });
            this.showNotification("success", "Successfully order dispatched!");
          }
        } else {
          // @ts-ignore
          document.getElementById("codNumber").select();
          this.showNotification("error", "This order is not confirmed!");
          this.updateOrderDetailsAfterUpdate();
        }
      }
    );
  }

  updateDeliveryStatus(newDeliveryStatus: string) {
    this.serviceClass.removeConfirmedOrderDetails(this.orderNumber, newDeliveryStatus, this.agentEmail).subscribe(
      data => {
        if (data == true) {
          this.showNotification('success', 'Delivery Status Updated to ' + newDeliveryStatus + ' Successfully!');
          this.updateOrderDetailsAfterUpdate();
        } else {
          this.showNotification('error', 'Error when updating the delivery Status **');
        }
      }
    );
    // @ts-ignore
    document.getElementById("codNumber").select();
  }

  updateOrderDetailsAfterUpdate() {
    this.serviceClass.getSingleOrderDetailsByCOD(this.codNumber, this.agentEmail).subscribe(
      data => {
        // @ts-ignore
        if (data.length !== 0) {
          this.item1 = this.getItemName(data[0].device1);
          this.qty1 = data[0].numDevice1;
          this.specialNote1 = data[0].specialNote1;

          this.item2 = this.getItemName(data[0].device2);
          this.qty2 = data[0].numDevice2;
          this.specialNote2 = data[0].specialNote2;

          this.item3 = this.getItemName(data[0].device3);
          this.qty3 = data[0].numDevice3;
          this.specialNote3 = data[0].specialNote3;

          this.item4 = this.getItemName(data[0].device4);
          this.qty4 = data[0].numDevice4;
          this.specialNote4 = data[0].specialNote4;

          this.item5 = this.getItemName(data[0].device5);
          this.qty5 = data[0].numDevice5;
          this.specialNote5 = data[0].specialNote5;

          this.customerName = data[0].customerName;
          this.phoneNumber = data[0].phoneNumber;
          this.telephoneNumber = data[0].fixedLine;

          this.totalAmount = data[0].totalAmount;
          this.agent = data[0].agent;
          this.addressFirstLine = data[0].addressFirstLine;
          this.addressNumber = data[0].addressNumber;
          this.addressSecondLine = data[0].addressSecondLine;
          this.city = data[0].city;
          this.deliveryDate = data[0].deliveryDate;
          this.deliveryStatus = data[0].deliveryStatus;
          this.deliveryType = data[0].deliveryType;
          this.specialNotes = data[0].specialNotes;
          this.orderNumber = data[0].orderNumber;
        }
      }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  dispatchTheOrder(orderDetails: NgForm) {
    this.codNumber = orderDetails.value["codNumber"];
    this.updateCodId();
  }
}

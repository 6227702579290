import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ServiceService} from "../../../service.service";
import {NgForm} from '@angular/forms';
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  canceledCount: any = 0;
  confirmedCount: any = 0;
  deliveredCount: any = 0;
  dispatchedCount: any = 0;
  pendingCount: any = 0;
  returnCount: any = 0;
  warrentyCount: any = 0;
  userName: any;
  emailAddress: any;
  searchKeyword: any;
  currentTime: any;
  userImageProfileImage: any;
  empEmail: any;

  constructor(private modalService: NgbModal, private router: Router, private serviceClass: ServiceService, private spinner: NgxSpinnerService) {
    setInterval(() => {
      let todayDate = new Date();
      const helper = new JwtHelperService();
      const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
      this.userName = dataValues.empName;
      this.emailAddress = dataValues.empEmail;
      this.userImageProfileImage = dataValues.imageThumbnail;
      this.currentTime = todayDate.getHours() + ":" + this.getTwoDigits(todayDate.getMinutes()) + ":" + this.getTwoDigits(todayDate.getSeconds()) + " " + todayDate.getDate() + "/" + (todayDate.getMonth() + 1) + "/" + todayDate.getFullYear();
    }, 1000);
  }

  getTwoDigits(value: any) {
    let valueNew = value.toString();

    if (valueNew.length == 1) {
      return "0" + value;
    } else {
      return value;
    }
  }

  ngOnInit(): void {
    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
    this.userName = dataValues.empName;
    this.empEmail = dataValues.empEmail;
    this.userImageProfileImage = dataValues.imageThumbnail;
  }

  logout() {
    localStorage.setItem('user_details', "")
    this.router.navigate(['/']);
  }

  updateDayOrderCount() {
    this.serviceClass.getAllItemCounts().subscribe(
      data => {
        // @ts-ignore
        this.canceledCount = data.canceledCount;
        // @ts-ignore
        this.confirmedCount = data.confirmedCount;
        // @ts-ignore
        this.deliveredCount = data.deliveredCount;
        // @ts-ignore
        this.dispatchedCount = data.dispatchedCount;
        // @ts-ignore
        this.pendingCount = data.pendingCount;
        // @ts-ignore
        this.returnCount = data.returnCount;
        // @ts-ignore
        this.warrentyCount = data.warrentyCount;
      }
    );
  }

  getImageUrl(){
    if(this.userImageProfileImage == null){
      return "../../../../assets/images/users/userProfileIcon.png";
    }else{
      return this.userImageProfileImage;
    }
  }

  routeToMainPage(searchingValues: NgForm) {
    const helper = new JwtHelperService();
    let empType = "";
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];

    if (dataValues["empPost"] == "admin") {
      empType = "admin";
    } else {
      empType = "user";
    }
    this.showSearch = false;
    this.router.navigate(['/' + empType + '/main'], {queryParams: {keyword: searchingValues.value["searchKeyword"]}});
  }

  routeToSingleOrderPage() {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/profile']);
  }
}

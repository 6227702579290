<div class="row">
  <div class="col-lg-7">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Order Entering Panel <i (click)="refreshButtonValues()"
                                                       class="sl-icon-refresh refreshButton"></i></h3>
        <h6 class="card-subtitle">This pannel can enter an order to the system.</h6>

        <mat-accordion class="example-headers-align">
          <mat-expansion-panel (opened)="setStep(0)" [expanded]="step === 0" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>account_circle</mat-icon>
                Personal data
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Customer Name</label>
                <input [(ngModel)]="name_edit" class="form-control" ngModel type="text" value="{{name_edit}}"/>
              </div>
            </div>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Phone Number</label>
                <input (change)="checkPreviousOrders()" [(ngModel)]="phone_edit" class="form-control" ngModel
                       type="text" value="{{phone_edit}}"/>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Telephone Number</label>
                <input (change)="checkPreviousOrders()" [(ngModel)]="telephone_edit" class="form-control" ngModel
                       type="text" value="{{telephone_edit}}"/>
              </div>
            </div>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">NIC Number</label>
                <input (change)="checkPreviousOrders()" [(ngModel)]="nic_edit" class="form-control" ngModel type="text"
                       value="{{nic_edit}}"/>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Delivery Date</label>
                <input [(ngModel)]="deliveryDate_edit" class="form-control" ngModel type="date"/>
              </div>
            </div>

            <div class="row">
              <div class="col-2 m-t-10 m-b-10">
                <label class="form-control-label">No</label>
                <input [(ngModel)]="addressNo_edit" class="form-control" ngModel type="text"
                       value="{{addressNo_edit}}"/>
              </div>

              <div class="col-4 m-t-10 m-b-10">
                <label class="form-control-label">First Line</label>
                <input [(ngModel)]="firstLine_edit" class="form-control" ngModel type="text"
                       value="{{firstLine_edit}}"/>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Second Line</label>
                <input [(ngModel)]="secondLine_edit" class="form-control" ngModel type="text"
                       value="{{secondLine_edit}}"/>
              </div>
            </div>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">City</label>
                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("city",$event)'
                  [data]="cityDataListJson"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="city_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="specialNotes_edit" class="form-control" ngModel type="text"
                       value="{{specialNotes_edit}}"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Delivery Type</label>

                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("deliveryType", $event)'
                  [data]="deliveryTypeArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="deliveryName"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>

            <mat-action-row>
              <button (click)="nextStep()" color="primary" mat-button>Next</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setStep(1)" [expanded]="step === 1" hideToggle>
            <mat-expansion-panel-header class="headerBackground">
              <mat-panel-title>
                <i class="sl-icon-social-dropbox"> Item 1 </i>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">First Item</label>
                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("item1", $event)'
                  [data]="phoneDetailsJsonArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="firstItem_name_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Number Of Items</label>
                <input (change)="updatePriceTable()" [(ngModel)]="firstItemCount_edit" class="form-control" min="0"
                       ngModel type="number" value="{{firstItemCount_edit}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="firstItemNote_edit" class="form-control" ngModel type="text"
                       value="{{firstItemNote_edit}}"/>
              </div>
            </div>

            <mat-action-row>
              <button (click)="prevStep()" color="warn" mat-button>Previous</button>
              <button (click)="nextStep()" color="primary" mat-button>Next</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setStep(2)" [expanded]="step === 2" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-social-dropbox"> Item 2 </i>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Second Item</label>
                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("item2", $event)'
                  [data]="phoneDetailsJsonArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="secondItem_name_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Number Of Items</label>
                <input (change)="updatePriceTable()" [(ngModel)]="secondItemCount_edit" class="form-control" min="0"
                       ngModel type="number" value="{{secondItemCount_edit}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="secondItemNote_edit" class="form-control" ngModel type="text"
                       value="{{secondItemNote_edit}}"/>
              </div>
            </div>

            <mat-action-row>
              <button (click)="prevStep()" color="warn" mat-button>Previous</button>
              <button (click)="nextStep()" color="primary" mat-button>Next</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setStep(3)" [expanded]="step === 3" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-social-dropbox"> Item 3 </i>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Third Item</label>

                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("item3", $event)'
                  [data]="phoneDetailsJsonArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="thirdItem_name_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Number Of Items</label>
                <input (change)="updatePriceTable()" [(ngModel)]="thirdItemCount_edit" class="form-control" min="0"
                       ngModel type="number" value="{{thirdItemCount_edit}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="thirdItemNote_edit" class="form-control" ngModel type="text"
                       value="{{thirdItemNote_edit}}"/>
              </div>
            </div>

            <mat-action-row>
              <button (click)="prevStep()" color="warn" mat-button>Previous</button>
              <button (click)="nextStep()" color="primary" mat-button>Next</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setStep(4)" [expanded]="step === 4" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-social-dropbox"> Item 4 </i>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Fourth Item</label>

                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("item4", $event)'
                  [data]="phoneDetailsJsonArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="fourthItem_name_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Number Of Items</label>
                <input (change)="updatePriceTable()" [(ngModel)]="fourthItemCount_edit" class="form-control" min="0"
                       ngModel type="number" value="{{fourthItemCount_edit}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="fourthItemNote_edit" class="form-control" ngModel type="text"
                       value="{{fourthItemNote_edit}}"/>
              </div>
            </div>

            <mat-action-row>
              <button (click)="prevStep()" color="warn" mat-button>Previous</button>
              <button (click)="nextStep()" color="primary" mat-button>Next</button>
            </mat-action-row>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="setStep(5)" [expanded]="step === 5" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="sl-icon-social-dropbox"> Item 5</i>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Fifth Item</label>

                <ng-autocomplete
                  (change)="updatePriceTable()"
                  (selected)='setSelectBoxValues("item5", $event)'
                  [data]="phoneDetailsJsonArray"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="fifthItem_name_edit"
                  [searchKeyword]="keyword"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>

              <div class="col-6 m-t-10 m-b-10">
                <label class="form-control-label">Number Of Items</label>
                <input (change)="updatePriceTable()" [(ngModel)]="fifthItemCount_edit" class="form-control" min="0"
                       ngModel type="number" value="{{fifthItemCount_edit}}"/>
              </div>

              <div class="col-12 m-t-10 m-b-10">
                <label class="form-control-label">Special Note</label>
                <input [(ngModel)]="fifthItemNote_edit" class="form-control" ngModel type="text"
                       value="{{fifthItemNote_edit}}"/>
              </div>
            </div>

            <mat-action-row>
              <button (click)="nextStep()" color="primary" mat-button>Done</button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="col-lg-5">
    <div *ngIf="shouldShowTheDetails()" class="card">
      <div class="card-body mainBlockColor {{backgroundColorOfBlock}}">
        <br/>
        <h1 class="orderDetails">Total Price : {{totalPrice}}Lkr</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6 m-t-10 m-b-10" *ngIf="shouldShowTheDetails()">
            <h4 *ngIf="shouldShowTheDetails()" class="card-title">Set Envelope Size</h4>
            <label class="form-control-label">Envelope Size</label>
            <select [(ngModel)]="envelope" class="form-control" type="text" value="{{envelope}}">
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
          </div>
          <div class="col-6 m-t-10 m-b-10" *ngIf="shouldShowTheDetails()">
            <h4 *ngIf="shouldShowTheDetails()" class="card-title">Set Envelope Size</h4>
            <label class="form-control-label">Discount</label>
            <input [(ngModel)]="discount" class="form-control" ngModel type="number" (change)="updatePriceTable()"
                   value="{{discount}}"/>
          </div>
        </div>
        <br/>

        <h4 *ngIf="shouldShowTheDetails()" class="card-title">Order Timeline</h4>
        <div class="row">
          <div class="col-12">
            <div *ngIf="shouldShowTheDetails()" class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Sub Total</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="firstItem_edit != '' ">
                      <td>{{firstItem_name_edit}}</td>
                      <td>{{firstItemCount_edit}}</td>
                      <td>{{item1PriceSub}}</td>
                      <td><i (click)="removeAddedItem('1')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="secondItem_edit != '' ">
                      <td>{{secondItem_name_edit}}</td>
                      <td>{{secondItemCount_edit}}</td>
                      <td>{{item2PriceSub}}</td>
                      <td><i (click)="removeAddedItem('2')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="thirdItem_edit != '' ">
                      <td>{{thirdItem_name_edit}}</td>
                      <td>{{thirdItemCount_edit}}</td>
                      <td>{{item3PriceSub}}</td>
                      <td><i (click)="removeAddedItem('3')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="fourthItem_edit != '' ">
                      <td>{{fourthItem_name_edit}}</td>
                      <td>{{fourthItemCount_edit}}</td>
                      <td>{{item4PriceSub}}</td>
                      <td><i (click)="removeAddedItem('4')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="fifthItem_edit != '' ">
                      <td>{{fifthItem_name_edit}}</td>
                      <td>{{fifthItemCount_edit}}</td>
                      <td>{{item5PriceSub}}</td>
                      <td><i (click)="removeAddedItem('5')" class="fas fa-trash trashIconInLine"></i></td>
                    </tr>
                    <tr *ngIf="this.deliveryName != '' ">
                      <td colspan="2"><b>Delivery Cost ({{deliveryName}})</b></td>
                      <td>{{deliveryPrice}}</td>
                    </tr>
                    <tr *ngIf="this.discount > 0 ">
                      <td colspan="2"><b>Discount (Fixed amount)</b></td>
                      <td>{{discount}}</td>
                    </tr>
                    <tr>
                      <td colspan="2"><b>Total Price</b></td>
                      <td>{{totalPrice}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div *ngIf="shouldShowTheDetails()" class="col-12 m-t-10 m-b-10">
              <button (click)="addOrder()" class="btn btn-rounded btn-block btn-outline-success" type="button" [disabled]="disableAddOrderButton">
                Place The Order
              </button>
            </div>

            <div *ngIf="previousOrdersArray.length > 0" class="card">
              <div class="card-body">
                <h4 class="card-title">Previous Orders</h4>
                <ul class="timeline timeline-left">
                  <li *ngFor="let indexElement of previousOrdersArray" class="timeline-inverted timeline-item">
                    <div class="timeline-badge success nameIcon">{{indexElement.COD}}</div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h3 class="timeline-title usersName">Delivery Status : <span
                          class="label {{getLabelType(indexElement.deliveryStatus)}}">{{indexElement.deliveryStatus}}</span>
                        </h3>
                        <h5
                          class="timeline-title usersName">{{formatDate(indexElement.date) + " | " + indexElement.customerName}}</h5>
                        <p>
                          <small class="text-muted">
                            <i class="fa fa-clock-o"></i> Last Updated Agent Name : {{indexElement.agent}}<br/>
                            <i class="fa fa-clock-o"></i> Note 1 : {{indexElement.note1}}<br/>
                            <i class="fa fa-clock-o"></i> Note 2 : {{indexElement.note2}}<br/>
                            <i class="fa fa-clock-o"></i> Note 3 : {{indexElement.note3}}<br/>
                          </small>
                        </p>
                      </div>
                      <div class="timeline-body">
                        <p>@Item 1 : {{indexElement.device1}} | Qty : {{indexElement.numDevice1}}</p>
                        <p>@Item 2 : {{indexElement.device2}} | Qty : {{indexElement.numDevice2}}</p>
                        <p>@Item 3 : {{indexElement.device3}} | Qty : {{indexElement.numDevice3}}</p>
                        <p>@Item 4 : {{indexElement.device4}} | Qty : {{indexElement.numDevice4}}</p>
                        <p>@Item 5 : {{indexElement.device5}} | Qty : {{indexElement.numDevice5}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<notifier-container></notifier-container>
